.fixed-join {
	background: linear-gradient(-180deg, darken($secondary, 10%) 60%, darken($secondary, 20%) 100%);
	@include fixed(bottom 0);	
	width: 100%;
	padding: .35rem;
	background: $secondary;
	box-shadow: .2rem .2rem .5rem 0 rgba($black,.5);
	transform: translateY(100%);
	transition: $slow;
	transition-delay: 0s;
	z-index: 1000;
	&-grid {
		width: 100%;
		max-width: $max-width-text;	
		text-align: center;
		color: $white;
		margin: 0 auto;
		padding: .5rem;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		align-content: stretch;
		align-items: center;			
		@include tablet {
			padding: 1rem;			
		}
		@include desktop {
		}	
		&:hover {
			.fixed-join {
				&-button {
					background: $primary;
					color: $white;
					transform: scale(1.05);
				}	
			}
		}
		&:focus {
    	box-shadow: 0 0 0 4px $green;
			.fixed-join {
				&-button {
					background: $primary;
					color: $white;
					transform: scale(1.05);
				}	
			}
		}		
	}	
	&-text {
		@include desktop {
			padding-right: 3rem;
		}
		
	}
	&-headline {
		font-family: $headline;
		font-size: 1.8rem;	
		margin: 0;
		text-align: left;
		@include tablet {
			font-size: 2rem;
		}
		@include desktop {
			font-size: 2.2rem;
		}			
	}	
	&-blurb {
		line-height: 1.1;
		text-align: left;		
		@include tablet {
			font-size: 1.15rem;	
		}
		@include desktop {
			font-size: 1.25rem;				
		}	
	}	
	&-button {
		background: $white;
		color: $secondary;
		padding: 1rem;
		font-family: $headline;
		text-transform: uppercase;
		transition: $bounce;
		white-space: nowrap;
		@include tablet {
			padding: 1rem 1.5rem;
		}
		@include desktop {
			padding: 1rem 2rem;
		}		
	}
}
.fixed {
	.fixed-join {
  	transition-delay: .3s;
		transform: translateY(0);
	}
}
