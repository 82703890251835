.pagination {
  margin-top: 1rem;
  text-align: center;
	@extend %slide-up;	
	&.reveal {
		@extend %slide-up-reveal;	
	}  
  &-list {
    list-style: none;
    padding: 0;
    margin: 0 auto;    
    &-item {
      padding: 0 .75rem;
      margin: 0;
      display: inline-block;
      text-transform: uppercase;
    }
    &-link {
      display: block;
      color: $gray !important;
      transition: $slow;
      &:hover {
        color: $primary !important;
        .pagination-arrow {
          fill: $primary;
        }        
      }
      &.active {
        color: $primary !important;
      }
    }
  }
  &-arrow {
    display: inline;
    width: .5rem;
    fill: $gray;
    margin: 0 .2rem;
    transition: $slow;
  }
}
