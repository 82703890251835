.victory {
  &-banner {
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: center;
     align-content: center;
     align-items: stretch;
     margin: 0 auto;
     width: 100%;
     max-width: $desktop-medium;
     @include tablet {
       height: 8rem;
     }
     @include tablet {
       height: 10rem;
     }     
     &.add-padding {
       @include tablet {
         padding: 1rem 1rem 1.5rem;
       }
     }
    	&.top_margin {
    	  @extend %block-margin-top;
    	}	
    	&.bottom_margin {
    	  @extend %block-margin-bottom;
    	}		      
    	&.photo_first {
	      flex-direction: row-reverse;
    	}
    	@extend %slide-up;		
     &.reveal {
     	@extend %slide-up-reveal;		
     }    	
     &.no_photo {
       .victory-banner-headline {
         width: 100%;
       }
     }
     &-headline {
       background: $darkgreen;
       width: 50%;
       display: flex;
       flex-wrap: wrap;
       justify-content: center;
       text-align: center;
       align-items: center;
       align-content: center;
       padding: 1rem;
       line-height: .75;
       &-txt {
         width: 100%;
         display: block;
         @extend %headline2;
         font-family: futura-pt, sans-serif;
         color: $yellow;
         text-transform: uppercase;         
       }
       svg {
         display: block;
         width: 10rem;
         margin-bottom: .75rem;
         fill: $white;
         @include desktop {
           margin-bottom: 1rem;
         }
       }
     }
     &-image {
       background-position: center center;
       background-repeat: no-repeat;
       background-size: cover;
       width: 50%;
     }
  }
}

