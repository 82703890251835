.fixed-cta {
   background-color: $lightgray;
   width: 100%;
   box-shadow: 0 -.35rem 1rem 0 rgba(0,0,0,.25);
   @include fixed(left 0 bottom 0);
   z-index: 700;
   &-wrap {
      max-width: $desktop;
      margin: 0 auto;
      padding: .5rem;
      @include desktop {
         padding: 2rem;
      }
   }
   &-headline {
      @extend %headline4;
      color: $gray;
      margin-bottom: .5rem;
   }
   .button {
      @include phone {
         padding-right: .5rem;
         padding-left: .5rem; 
         margin: 0 .15rem;
         flex: 1;
      }
      &-text {
        @include phone {
           font-size: .7rem;
        }
      }
      &-block {
         display: flex;
         opacity: 1;
         margin: 0 auto;
         transform: translateY(0);
         @include tablet {
            display: inline-flex;
         }
      }
   } 
   &-form {
      max-height: 0px;
      visibility: hidden;
      overflow: hidden;
      transition: .3s all $slow;
      &.expand {
         max-height: 1000px;
         visibility: visible;
      }
      .join-layer {
          padding: 1rem;
          @include tablet {
            padding: 2rem;
          }
          @include desktop {
            padding: 3rem;
          }     
          &-content {
            opacity: 1;
            transform: translateY(0);
          }
      }
   }
}
.modal {
  &-video {
     width: 100%;
     max-width: 55rem;
     position: relative;
     .modal_close {
        z-index: 1000;
     }
  }
  &-player {
     &-aspect {
        max-height: none;
        max-width: none;
      		@include aspect(16,9);
     }
     &-embed  {
      	  @include size(100%);
      	  @include absolute(left 0 top 0);
     }
  }
}
