.hero {
	background: $primary;
	@include size(100%, 70vh);	
	position: relative;
	@include tablet {
 		@include size(100%, auto);
 		@include aspect(1600,600);
	}		
	&.short {
  	@include tablet {
   		@include aspect(1600,400);
  	}		
	}
		&.very_short {
  	@include tablet {
   		@include aspect(1600,300);
  	}	
  	.hero-text {
     	@include tablet {
   			bottom: 1.5rem;		
   		}		
   		@include desktop {
   			bottom: 1.75rem;		
   		}	
  	}
	}
		&-language {
  		opacity: 0;
  		transition: $veryslow;
  		transition-delay: 1s !important;	 	  
  		display: inline-block;
  		@include absolute(right 1rem bottom 0);
  		transform: translateY(50%);
  		z-index: 500;
 	  &-link {
 	     color: $white;
 	     font-weight: 900;
 	     text-transform: uppercase;
 	  }
	} 
	&.darkgreen {
	  background-color: darken($primary,.3);
	}	
	&.blue {
	  background-color: $secondary;
	}
	&.darkblue {
	  background-color: darken($secondary,.3);
	}	
	&.gray {
	  background-color: $gray;
	}	
	&.black {
	  background-color: $black;
	}		
	&.white {
	  background-color: $white;
	}		
	&-text {
		max-width: $max-width-text;
		padding: 0 1rem;	
		width: 100%;
		@include absolute(left 50% bottom 3rem);
		transform: translateX(-50%);
		z-index: 200;
		@include tablet {
			padding: 0 2rem;
			bottom: 3rem;		
		}		
		@include desktop {
			bottom: 4rem;		
		}	
		@include ie {
			width: auto !important;	
		}	
		@include edge {
			width: auto !important;	
		}				
	}
	&-pre-headline {
		background: $white;
		color: $primary;
		font-family: $headline;
		display: table;
		margin: 0 auto;
		text-transform: uppercase;
		padding: .25rem .45rem;
		margin-bottom: .5rem;
		opacity: 0;
		position: relative;
		z-index: 200;
		transition: $slow;
		transition-delay: .3s;
    @extend %headline5;
	  &:hover {
	    background: darken($primary, 10%);
	    color: $white;
	  }
	  &:focus {
	    background: darken($primary, 10%);
	    color: $white;
    	box-shadow: 0 0 0 4px $white;
	  }	  
	  
	}
	&-headline {
		color: $white;
		font-family: $headline;
		line-height: 1;
		opacity: 0;
		transition: $veryslow;
		transition-delay: .5s !important;	
    @extend %headline1;
    &-link {
      color: $white;
    }
	}
	&-blurb {
		color: $white;
		font-size: 1.1rem;
		line-height: 1.2;
		opacity: 0;
		transition: $veryslow;
		transition-delay: .7s !important;	
		margin: 1.5rem 0 1rem;
		@include tablet {
			font-size: 1.2rem;
		}		
		@include desktop {
			font-size: 1.4rem;
		}		
		p {
			margin-bottom: 1rem;
		}
	}	
	&-grad {
		@include size(100%, 60%);	
		@include absolute(left 0 bottom 0);			
		z-index: 50;
		display: block;
		background: linear-gradient(0deg, rgba($black,.7) 0%, rgba($black,0) 100%);
		&.darker {
  		background: linear-gradient(0deg, rgba($black,.85) 35%, rgba($black,0) 100%);
		}
	}	
	&-image-credit {
	  background: rgba($black,.3);
		@include absolute(left 0 bottom 0);
    color: $white;
    font-size: 75%;
    padding: .4rem .4rem;
    z-index: 500;
	}
	&-photo {
		@include size(100%, 100%);	
		@include absolute(left 0 top 0);
		opacity: 0;
		transition: $slow;
		z-index: 1;
		&-pic {
			@include size(100%);	
			@include absolute(left 0 top 0);
			object-fit: cover;
			overflow: hidden;	
		}
		&-img {
			@include size(100%);	
			object-fit: cover;
		}				
	}
  &-video { 
    @include phone {
      display: none;
    }
  		@include size(100%, 100%);	
  		@include absolute(left 0 top 0);
  		opacity: 0;
  		transition: $slow;
  		z-index: 2;    
  		overflow: hidden;
    	&-player {
       @include absolute(left 0 top 0);
     		@include size(100%, 100%);	
       display: block;
       z-index: 3; 
       overflow: hidden;	
       object-fit: cover;
    	}
  }	
	.button {
		vertical-align: middle;
		&-block {
  		transition-delay: .9s !important;	
			margin-top: 1rem;
			transition: $slow;
	    opacity: 0;
	    transform: translateY(0);
	    &.reveal {
	      opacity: 1;
	    }
			&-join {
				margin-top: 0;
			}
			@include tablet {
				margin-top: .75rem;
			}		
			@include desktop {
				margin-top: 1rem;
			}			
		}
		&-text {
		}
	}
	&.reveal {
		.hero {
			&-pre-headline,
			&-headline,
			&-blurb,
			&-photo,
			&-video, 
			&-language {
				opacity: 1;	
				transition-delay: none !important;
			}			
		}		
	}
	&.no-photo {
		height: auto;
		padding-top: 0;
		.hero-text {
			@include relative(left auto bottom auto);
			margin: 0 auto;
			transform: translateX(0);
			padding: 2rem;
			@include tablet {
				padding: 4rem 2rem 2rem;
			}		
			@include desktop {
				padding: 6rem 2rem 2rem;
			}				
		}
	}	
	&.child {
		.hero {
  		&-headline {
  			background: $secondary;
  			display: table;
  			margin: 0 auto;
  			padding: 1.5rem 1.5rem;
  			position: relative;
  			top: -1.5rem;
  			margin-bottom: 0rem;
  		}
    	&-blurb {
    		margin: -.5rem 0 0;
    	}	  
		}
	}
	&-logo {
		background: $white;
		width: 10rem;
		height: 10em;
		display: block;
		border-radius: 50%;
		padding: 3rem;
		margin: 0 auto 1.5rem;		
		position: relative;
		&-svg,
		svg, img {
			width: 80%;
			max-height: 8rem;
			@include absolute(left 50% top 50%);
			transform: translateX(-50%) translateY(-50%);
			.green-fill {
				fill: $primary;
			}
			.blue-fill {
				fill: $secondary;
			}			
		}
	}	
}



.victory {
 .hero {
  	background: $primary;
  	@include size(100%, auto);	
  	position: relative;
  	@include tablet {
  	}		
  	@include desktop {
  	}
  	&-video {
     @include tablet {
       display: block;
     }
  	  &-player {
    	  @include size(100%);
  	  }
  	}
  	&-headline {
     display: none;
  	  @include tablet {
  	  }
  	}
 }
}
