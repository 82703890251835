.share {
  text-align: center;
  margin-top: 1rem;
  &-grouping {
	  @extend %block-padding-top-small;	
	  @extend %block-padding-bottom-small;	
		&.no_top_padding {
		  padding-top: 0;
		}
		&.no_bottom_padding {
		  padding-bottom: 0;
		}			  
    &-headline {
      text-align: center;
      margin-bottom: 1rem;
			@extend %slide-up;		
  		&.reveal {
  			@extend %slide-up-reveal;		
  		} 	      
    }
    .button-block {
      transform: none !important;
      opacity: 1 !important;
      display: flex;
      justify-content: center;
      align-items: center;      
      .button {
        padding: .75rem;
        flex: 0 1 auto;
        margin: 0 .15rem;
        &-text {
          font-size: .8rem;
          line-height: 1;
          display: block;
        }
      }
    }
  }
  &-label  {
    font-size: 80%;
    text-transform: uppercase;
    color: $gray;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
  }
  &-links {
    display: inline-block;
    vertical-align: middle;    
    &-item {
      vertical-align: middle;    
      @include size(2.5rem);
      @include desktop {
        @include size(2rem);
      }
      border-radius: 50%;
      margin: 0 .25rem;
      display: inline-block !important;
      background: $secondary;
      position: relative;
      transition: $bounce;
      &:hover {
        background: $primary;
        transform: scale(1.1);
      }
      &-icon {
  			@include absolute(left 50% top 50%);
  			transform: translateX(-50%) translateY(-50%);
        width: 60%;
        fill: $white;
      }
    }
    
  }
  &-box.menu-box {
    text-align: center;
    .share {
      margin-bottom: 1rem;
      &-item {
        @include size(3rem);
      }
      &-label  {
        display: block;
        margin-bottom: .5rem;
      }  
    }  
  }
}
