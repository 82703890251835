.page-meta {
  @extend %block-padding-bottom-small;
  text-align: center;
  @extend %slide-up;
  &.reveal {
    @extend %slide-up-reveal;
  }
  &.no-bottom-margin {
    margin-bottom: 0;
  }
  &-date, &-author, &-source {
    text-transform: uppercase;
    font-size: 80%;
  }
  &-tag {
    display: block; 
    &-link {
      font-size: 80%;
      background: $lightgray;
      color: $gray !important;
      display: inline-block;
      margin: .01rem;
      padding: 0 .2rem;
      transition: $slow;
      &:hover {
        background: $primary;
        color: $white !important;
      }
    }
  }
}
