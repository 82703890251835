.leadership {
	&-layer {
		@extend %block-padding;
  	&-headline {
  		color: $gray;
  		margin: 0 auto 1rem auto;
  		transition-delay: .2s !important;
			@extend %headline3;		
			@extend %slide-up;
  		@include desktop {
  			margin-bottom: 1rem;				
  		}	
  		@include lg {
  			margin-bottom: 1.5rem;				
  		}	
  		&.reveal {
  			@extend %slide-up-reveal;		
  		}
  		&-link {
  		  color: $primary;
  		  transition: $slow;
  		  padding: .25rem;
  		  &:hover {
  		    color: $secondary;
  		  }
  		  &:focus {
    	    box-shadow: 0 0 0 4px $primary;
  		  }
  		}
  	}				
	}
	&-list {
		width: 100%;
		max-width: $desktop-medium;	
		text-align: center;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-content: stretch;
		align-items: flex-end;		
		&.stacked {
		  .leadership {
		    &-item {
		      width: 100%;
      		&-headshot {
      		  @include desktop {
        			width: 15%;
      		  }
      		}
      		&-headline {
      		  @include desktop {
        		  font-size: 200%;
      		  }
      		}			      
		    }
		  }
  		.button-block {
  		  margin-top: 1rem;
  		}		  
		}
	}
	&-item {
		@extend %slide-up;
		transition: $slow;
		@include tablet {
			width: 50%;
		}
		@include desktop {
			width: 33.33%;
		}		
		&.reveal {
			@extend %slide-up-reveal;		
		}
		&-grid {
			width: 100%;
			text-align: center;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: center;
			align-content: stretch;
			align-items: center;
			padding: .5rem;
			transition: $slow;
			@include tablet {
			  padding: 1rem;
			}
			@include desktop {
			  padding: 1.25rem;
			}			
			@include lg {
			  padding: 1.5rem;
			}						
			&:hover {
		    background: $lightestgray;
			  .leadership-item {
			    &-headline {
  			    color: $primary;
			    }
      		&-location,
      		&-job-title {
  			    color: $gray;
      		}
      		&-headshot {
      		  transform: scale(1.05);
      		  box-shadow: $button-shadow;
      		}
			  }
			}
			&:focus {
  	    box-shadow: 0 0 0 4px $primary;
			}
		}	
		&-headshot {
			border-radius: 50%;
			width: 30%;
			transition: $bounce;
			margin-right: .5rem;
			@include tablet {
				margin-right: .75rem;
			}
			@include desktop {
			  margin-right: 1.25rem;
			}				
		}
		&-headline {
			text-align: left;
			color: $gray;
			flex: 1;
			margin-bottom: 0;
			line-height: 1;
			transition: $slow;
			@extend %headline6;	
		}
		&-location,
		&-job-title {
			font-size: 85%;
			font-style: normal;
			display: block;
			font-family: $body;
			font-weight: 400;
			margin-top: .25rem;
		}
	}
	&-layer.executive {
		background-color: $lightestgray;
		@extend %block-padding-small;
		.leadership-item {
  		@include desktop {
  			width: 50%;
  		}	
  		&-grid {
  		  &:hover {
  		    background: $white;
  		  }  		  
  		}
  		&-headline {
  		  @include desktop {
    		  font-size: 200%;
  		  }
  		}
  		.button-block {
  		  margin-top: 1rem;
  		}
  		.button {
  		  margin-left: 0;
  		  &:hover {
  		    transform: scale(1);
  		  }
  		}
		}
	}	
}
.grid-layer-col {
  .leadership {
    &-promo {
      @include phone {
        margin-bottom: 1.5rem;
      }
    }
  	&-layer-headline {
			@extend %headline3;	
			margin-top: 1rem;
  		@include tablet {
  		  margin-top: 0;
  		}	  			
  		@include desktop {
  			margin-bottom: .75rem;				
  		}	
  		@include lg {
  			margin-bottom: 1rem;				
  		}	
  	}				
    &-item {
  		&-grid {
  			@include tablet {
  			  padding: .5rem;
  			}
  			@include desktop {
  			  padding: .75rem;
  			}			
  			@include lg {
  			  padding: 1rem;
  			}	
  		}        
    }
  }
}
