.banner-buttons {
	background: $lightestgray;
	padding: 0 1rem .5rem;
	@include tablet {
  	padding: 1.5rem;
	}
	@include desktop {
  	padding: 2rem;
	}	
	&-grid {
		max-width: $max-width;
		width: 100%;
		margin: 0 auto;
		@include tablet {
			width: 100%;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-content: flex-start;
			align-items: stretch;			
		}
	}	
	&-item {
		background: $white;
		text-align: left;
		display: block;
		margin: 1rem auto;		
		transition: $bounce;
		@extend %slide-up;	
		@include tablet {
			flex: 1;
			margin: 1rem;
		}
		&:hover {
			box-shadow: $shadow;
			transition-delay: 0;

		}
		&:nth-child(2) {
			transition-delay: .1s;
		}
		&:nth-child(3) {
			transition-delay: .2s;
		}			
		&.reveal {
				@extend %slide-up-reveal;		
		}
	}	
	&-link {
		width: 100%;
		height: 100%;
		padding: 1rem;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-content: flex-start;
		align-items: center;	
		@include tablet {
			padding: 1.5rem;
		}		
		&:focus {
    	box-shadow: $button-shadow-hover, 0 0 0 4px $green;
		}
	}	
	&-logo {
		width: 35%;
		min-height: 2rem;
		margin-right: 1.5rem;
	}	
	
	&-headline {
		font-family: $headline;
		color: $primary;
		transition: $slow;
		font-size: 1.1rem;
		@include tablet {
			font-size: 1.3rem;
		}		
		@include desktop {
			font-size: 1.5rem;
		}		
	}
	&-blurb {
		color: $gray;
		margin-top: .5rem;
		@include tablet {
			font-size: 1.1rem;
		}		
		@include desktop {
			font-size: 1.2rem;
		}		
	}	
	&-item.green {
		background: $primary;
		&:hover {
			background: $secondary;
		}
		.banner-buttons-link {
			padding: 2rem;		
		}		
		.banner-buttons-headline {
			color: $white;		
			font-size: 1.3rem;
			@include tablet {
				font-size: 1.6rem;
			}		
			@include desktop {
				font-size: 1.8rem;
			}	
		}
		.banner-buttons-blurb {
			color: $white;		
		}					
	}
}
