.latest {
	position: relative;
	z-index: 100;
	&-grid {
		max-width: $max-width;
		width: 100%;
		margin: 0 auto;
		@include tablet {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: flex-start;
			align-items: stretch;			
		}
		@include desktop {
			width: 90%;		
		}			
	}
	&-left {
		@include tablet {
			padding: .5rem;
			width: 67%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: flex-start;
		}
		@include desktop {
			padding: 1rem;
		}		
	}
	&-feed {
		width: 100%;
		padding: 0;
		@include tablet {
			padding: 0;			
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: flex-start;
		}
		@include desktop {
		}		
		&-more {
		   width: 100%;
		   text-align: center;
		   margin-top: 1rem;
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: center;
     align-content: stretch;
     align-items: center;		   
		   @include desktop {
		    margin-top: 2rem;
		   }
		   .button {
		      margin: 0 auto !important;
		   }
		}
	}		
	&-right {
		text-align: left;
		padding: 0 1rem 0 1rem;
		@include tablet {
			width: 33%;
			padding: 0 1rem 0 0;			
		}
		@include desktop {
		}		
	}
}
