.video {
	&-layer {
		background: $graybg;
		&.dark-green {
		   background-color: $darkgreen;
		}
		&.dark-blue {
		   background-color: $darkblue;
		}		
		&.green {
		   background-color: $green;
		}				
		&.white {
		   background-color: $white;
		}		
		@extend %block-padding;
    &-title {
      @extend %headline2;
      text-align: center;
      margin-bottom: 1rem;
      color: $gray;
      @include tablet {
        margin-bottom: 1.5rem;
      }
      @include desktop {
        margin-bottom: 2rem;
      }  
  		@extend %slide-up;	
  		&.reveal {
    		@extend %slide-up-reveal;	
  		}    
    }  		
		&-wrap {
			max-width: $desktop;
			margin: 0 auto;
			&.square {
			   max-width: $tablet;
			}
		}
		.button-block {
		  margin-top: 1rem;
  		@include tablet {
  		  margin-top: 1.25rem;
  		}		
  		@include desktop {
  		  margin-top: 1.75rem;
  		}		
  		@include xl {
  		  margin-top: 2rem;
  		}				  
		}
	}
	&-player {
    margin: 0 auto;
    position: relative;
    background: $secondary;	
    @extend %slide-up;
    &.square {
       padding-top: 100%;
    }
  		&.portrait_mode, &.vertical {
       padding-top: 216%;
    } 		
  		&.landscape {
       padding-top: 56.25%;
    } 		    
    &-width {
       margin: 0 auto;
       &.landscape {
             @include size(100vw, 56.25vw);
             max-width: 144vh;
             max-height: 81vh;		
 
       }       
       &.square_video, &.square {
          max-width: $tablet;
       }
       &.vertical, &.portrait_mode {
          max-width: 375px;
       }           
    }
  		&.dark-green {
  		   background-color: $darkgreen;
  		}
  		&.dark-blue {
  		   background-color: $darkblue;
  		}		
  		&.green {
  		   background-color: $green;
  		}				
  		&.white {
  		   background-color: $white;
  		}		    
    &-container {
      &.top_margin {
    	  @extend %block-margin-top;
    	}		
      &.bottom_margin {
    	  @extend %block-margin-bottom;
    	}	  
   		&.dark-green {
   		   background-color: $darkgreen;
   		}
   		&.dark-blue {
   		   background-color: $darkblue;
   		}		
   		&.blue {
   		   background-color: $blue;
   		}				   		
   		&.green {
   		   background-color: $green;
   		}				
   		&.white {
   		   background-color: $white;
   		}		    	
    	&.square {
    	  margin-left: auto;
    	  margin-right: auto;
    	}
     &.vertical {
        margin-left: auto;
        margin-right: auto;
        @include phone {
           padding: .5rem
        }
     }       	
   		&.portrait_mode {
   		 margin: 0 auto;
   		 @include tablet {
   		  width: 75%;
   		 }
   		 @include desktop {
   		  width: 50%;
   		 }   		 
   		}
    	
    }
		&.reveal {
			@extend %slide-up-reveal;	
		}		
		&.portrait_mode {
		 width: auto;
		 height: auto;		
		 max-width: none;
   max-height: none;		  
 		padding-top: 216% !important;		
 		.video-preview {
 		 background: transparent;
 		}
		}			
		&.square, &.vertical {
		  width: auto;
		  height: auto;		
		  max-width: none;
    max-height: none;		  
  } 		
  &.vertical {
  		padding-top: 216% !important;
  		margin: 0 auto;
  }   
		&.square {
  		padding-top: 100% !important;
  }    
		iframe, embed {
  		@include absolute(left 0 top 0);	
  	  @include size(100%);
		}
		&.active {
		  .video {
		    &-preview {
		      z-index: 1;
    		  opacity: 0;
    		  visibility: hidden;
		    }
		    &-embed {
		      z-index: 100;
		    }		    
		  }
		}
		&-container {
		 		    .video-player {
    		  width: auto;
    		  height: auto;		
    		  max-width: none;
        max-height: none;		 
        @include aspect(16,9);
		    }
		  &.content_width {
		    max-width: $desktop;
		    margin: 0 auto;

		  }
		  &.tiktok {
		     padding: 1rem;
		     @include desktop {
		        padding: 2rem;
		     }
		     .tiktok-embed {
		        max-width: 375px !important;
		        padding: 1rem 0;
		     }
		     blockquote, 
		     .instagram-media {
		        margin: 0 auto !important;
		     }
		  }
		}
	}
	&-preview {
		background: $secondary;
		@include size(100%);
		@include absolute(left 0 top 0);	
		z-index: 50;
		cursor: pointer;
		overflow: hidden;
		display: block;
		margin: 0 auto;
		transition: $slow;
		&:hover {
			box-shadow: $shadow;
			.video-thumbnail {
				opacity: .1;
				transform: scale(1.1);
			}	
			.video-play-button {
				transform: scale(1.1);
			}			
			.video-play-icon {
				fill: $primary !important;
			}			
		}		
	}	
	&-embed {
	  @include size(100%);
		@include absolute(left 0 top 0);	
		z-index: 1;
	}	
	&-text {
		width: 100%;
		max-width: $tablet;
		@include absolute(left 50% bottom 0);	
		transform: translateX(-50%);
		z-index: 200;
		padding: 1rem;
		margin: 0 auto;
		text-align: center;
		transition: $slow;
		@include tablet {
			padding: 2rem;
		}		
		@include desktop {
			padding: 3rem;
		}		
		&.vert-center {
		  bottom: auto;
  		@include absolute(left 50% top 50%);	
  		transform: translateX(-50%) translateY(-50%);
		}
	}	
	&-logo {
		max-width: 7rem;
	}			
	&-headline {
		font-family: $headline;
		color: $white;
		font-size: 1.75rem;
		margin-bottom: .75rem;
		line-height: 1;
		@include tablet {
			font-size: 2rem;				
		}		
		@include desktop {
			font-size: 2.5rem;				
		}		
	}	
	&-blurb {
		color: $white;
		font-size: 1.2rem;
		margin: 0 auto;
		display: none;
		@include tablet {
		  display: block;
			font-size: 1.3rem;				
		}		
		@include desktop {
			font-size: 1.4rem;				
		}		
	}	
	&-thumbnail {
		@include size(100%);
		@include absolute(top 0 left 0);				
		background-repeat: no-repeat;  
		background-position: center center;
		background-size: cover;  		
		z-index: 100;
		transition: $veryslow; 
		opacity: .4;
	}	
	&-play {
		&-button {
			background: $white;
			border-radius: 50%;
			margin: 0 auto 1rem;
			transition: $bounce; 	
			position: relative;
			@include size(4rem);
      @include tablet {
  			@include size(5rem);
      }			
      @include desktop {
  			@include size(6rem);
      }	 
      @include md {
  			@include size(7rem);
      }	       
		}
		&-icon {
  		@include absolute(top 50% left 50%);				
      transform: translateX(-35%) translateY(-50%);		  
			width: 50%;
			fill: $secondary;
			transition: $slow;
		}
	}
	&-caption {
	  max-width: $desktop;
	  font-size: 95%;
	  padding: 1rem;
	  color: $gray;
	  line-height: 1.2;
	  text-align: center;
	  margin: 0 auto;
	  display: block;
	}
}
/**
.text-layer {
	.video-player {
	  @include size(100%, auto);
    max-height: none;
    max-width: none;
		@include aspect(16,9);
	}
}
**/
.grid-layer-col,
.text-layer,
.video-layer {
  .video-player {
	  @include size(100%);
    max-width: auto;
    max-height: auto;
		@include aspect(16,9);
  }
}
