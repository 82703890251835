.local-map {
	max-width: $desktop;
  margin: 0 auto;
  &-layer {
    background: $lightestgray;
  	@extend %block-padding-small;
  }
	&-svg {
		max-width: 100%;
		min-height: 10rem;
		filter: drop-shadow(0 .3rem .5rem rgba(0,0,0,.3));  
		@include ie {
  		min-height: 40rem;
		}
		a {
  		&:focus {
  		  .local-map-state {
  			  fill: darken($primary, 10%);
  		  }
  		}				  
		}
	}
	&-state {
		fill: $gray;
		transition: $bounce;		
		&:hover {
			fill: $primary;
			cursor: pointer;
		}
	}	
	&-mainland,
	&-island {
		stroke: $white;
		stroke-width: .03rem;
	}
	&-text {
		font-family: $body;
		font-weight: 400;
		font-size: .7rem; 
		fill: $white;
	}
}
.state {
  &-list {
    background: $white;
  	@extend %block-padding-small;
    &-wrap {
    	max-width: $desktop;
      margin: 0 auto;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			align-content: flex-start;
			align-items: stretch;			
    }
  }
  &-section {
    &.wide-items {
      .state-section-item {
        width: 100%;
      }
    }
  }
  &-item {
		@extend %slide-up;	
		text-align: left;
		font-size: 80%;
		width: 50%;
		@include tablet {
			width: 25%;
  		font-size: 90%;
		} 
		@include desktop {
			width: 20%;
		} 			
    &.reveal {
			@extend %slide-up-reveal;		
    }
    &-link {
      color: $gray;
      display: table;
      transition: $slow;
		  padding: .5rem .5rem;
      &:hover {
        color: $primary;
      }
      &:focus {
  	    box-shadow: 0 0 0 2px $green;
        color: darken($primary, 10%);
      }      
      
    }
  }
}

.ui-helper-hidden{display:none}
.ui-helper-hidden-accessible{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}
.ui-helper-reset{margin:0;padding:0;border:0;outline:0;line-height:1.3;text-decoration:none;font-size:100%;list-style:none}
.ui-helper-clearfix:before,.ui-helper-clearfix:after{content:"";display:table;border-collapse:collapse}
.ui-helper-clearfix:after{clear:both}
.ui-helper-zfix{width:100%;height:100%;top:0;left:0;position:absolute;opacity:0;filter:Alpha(Opacity=0)}
.ui-front{z-index:100}
.ui-state-disabled{cursor:default!important;pointer-events:none}
.ui-icon{display:inline-block;vertical-align:middle;margin-top:-.25em;position:relative;text-indent:-99999px;overflow:hidden;background-repeat:no-repeat}
.ui-widget-icon-block{left:50%;margin-left:-8px;display:block}
.ui-widget-overlay{position:fixed;top:0;left:0;width:100%;height:100%}
.ui-tooltip{
	background: $secondary;
	color: $white;	
	padding: .5em 1em .4em;
	box-shadow: $shadow;	
	position: absolute;
	z-index: 999999;
	max-width: 300px;
	border-radius: 1em;
}
body .ui-tooltip{border-width:2px}
