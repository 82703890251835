.retirees {
	&-facebook {
		@extend %slide-up;			
		&.reveal {
			@extend %slide-up-reveal;	
		}				
		&-aspect {
			background: $secondary;
			@include aspect(6,4);
			position: relative;
			overflow: hidden;
			display: block;
			transition: $bounce;
			box-shadow: $shadow;	
			&:hover {
				.retirees-facebook-photo {
					transform: scale(1.1);
				}
			}				
		}	
		&-text {
			width: 100%;
			@include absolute(left 0 top 50%);	
			transform: translateY(-50%);
			z-index: 200;
			padding: 1rem;
			margin: 0 auto;
			text-align: center;
			@include tablet {
				padding: 1.5rem;
			}		
			@include desktop {
				padding: 2rem;
			}		
		}	
		&-logo {
			max-width: 7rem;
		}			
		&-headline {
			font-family: $headline;
			color: $white;
			font-size: 1.5rem;
			margin: 0 auto;
			@include tablet {
				font-size: 1.7rem;				
			}		
			@include desktop {
				font-size: 2rem;				
			}		
		}			
		&-photo {
			@include size(100%);
			@include absolute(top 0 left 0);				
			background-repeat: no-repeat;  
			background-position: center center;
			background-size: cover;  		
			z-index: 100;
			transition: $veryslow; 
			opacity: .4;
			
			
		}			
	}
}
