.scorecard {
  &-title {
    @extend %headline2;
    color: $gray;
    margin-bottom: 1rem;
    @include tablet {
      margin-bottom: 1.25rem;
    }
    @include desktop {
      margin-bottom: 1.5rem;
    }     
		@extend %slide-up;		
		&.reveal {
			@extend %slide-up-reveal;		
		} 	    
  }
  &-intro {
		@extend %slide-up;		
		&.reveal {
			@extend %slide-up-reveal;		
		}    
  }
  &-list {
    margin: 1rem 0;
    padding: 0;
    list-style: none;
    @include tablet {
      margin: 1.25rem 0;
    }
    @include desktop {
      margin: 1.5rem 0;
    } 
    &-item {
      padding: 0;
      margin: 0;
  		@extend %slide-up;		
  		&.reveal {
  			@extend %slide-up-reveal;		
  		}       
    }
    &-link {
      display: block !important;
      padding: .25rem 0;
    }
  }
  &-past {
    &-title {
      font-size: 1.7rem !important;
      color: $gray;
  		@extend %slide-up;		
  		&.reveal {
  			@extend %slide-up-reveal;		
  		}       
    }
  }
}
