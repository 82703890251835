.careers {
  &-title {
    @extend %headline2;
    text-align: center;
    margin-bottom: 1rem;
    @include tablet {
      margin-bottom: 1.5rem;
    }
    @include desktop {
      margin-bottom: 2rem;
    }  
		@extend %slide-up;	
		&.reveal {
  		@extend %slide-up-reveal;	
		}    
  }  
  &-grouping {
    text-align: left;
    margin-bottom: 1rem;
    padding: 1rem;
    @include tablet {
      padding: 0;
    }    
    @include desktop {
      margin-bottom: 2rem;
    }
    &:last-of-type {
      margin-bottom: 2rem;
      @include desktop {
        margin-bottom: 0;
      }
    }
    &-title {
      color: $gray;
      @extend %headline3;
      margin-bottom: 1rem;
  		@extend %slide-up;	
  		&.reveal {
    		@extend %slide-up-reveal;	
  		}    
    }    
  }
  &-list {
    margin: 0 auto;
    padding: 0;
    &-item {
      list-style: none;
      padding: .25rem 0;
      margin: 0 auto;
      line-height: 1;
      @include tablet {
        padding: 0;
      }
  		@extend %slide-up;	
  		&.reveal {
    		@extend %slide-up-reveal;	
  		}
  		&.new {
        &:before { 
          background: $red; 
          content: "NEW"; 
          display: inline-block;
          font-size: .7rem;
          color: $white;
          padding: .1rem .2rem; 
          position: relative;
          top: -.2rem;
        }
  		}
    }
    &-link {
      display: inline-block;
      color: $primary;
      transition: $slow;
      padding: .25rem 0;
      @extend %basic-text;
      &:hover {
        color: $secondary;
      }
    }
  }
  &-header {
    background: $lightestgray;
    padding: 1rem;
    margin-bottom: 1rem;
    @include tablet {
      padding: 1.5rem;
      margin-bottom: 1.5rem;
    }
    @include desktop {
      padding: 2rem;
      margin-bottom: 2rem;
    } 
    @include lg {
      margin-bottom: 3rem;
    }   
		@extend %slide-up;	
		&.reveal {
  		@extend %slide-up-reveal;	
		}      
    &-title {
      @extend %headline4;
      text-align: center;
      margin-bottom: 1rem;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
    a {
      color: $primary;
      display: inline-block;
      transition: $slow;
      &:hover {
        color: $secondary;
      }
    }
  }
  &-footer {
    border-top: 1px solid $lightgray;
    font-size: 80%;
    padding-top: 1rem;
    margin-top: 2rem;
    color: $gray;
    @include desktop {
      margin-top: 3rem;
    }      
		@extend %slide-up;	
		&.reveal {
  		@extend %slide-up-reveal;	
		}      
  }
  &-table {
    margin-bottom: 1rem;
    @include tablet {
      margin-bottom: 1.5rem;
    }
    @include desktop {
      margin-bottom: 2rem;
    } 
    @include lg {
      margin-bottom: 3rem;
    }        
    &-row {
  		margin: 0 auto 1px;
  			max-width: $max-width;
  			padding: 0 0;			
  			display: flex;
  			flex-wrap: nowrap;
  			justify-content: flex-start;
  			align-content: flex-start;			  		
  		@include tablet {

  		}
  		@extend %slide-up;	
  		&.reveal {
    		@extend %slide-up-reveal;	
  		}    		
    }
    &-header {
      width: 10rem;
      font-weight: bold;
      padding: .4rem .6rem .4rem 0;
    }
    &-details {
      padding: .4rem .6rem .4rem 0;
    }    
  }
}
