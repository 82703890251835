.accordion-menu-box {
	margin: 0 auto;
	display: block;
	&-header {
		background: transparent;
		width: 100%;
		outline: 0;
		border: 0;
		cursor: pointer;
		display: flex;
		align-items: flex-start;
		&-icon {
			@include size(1.1rem);
			position: relative;
			top: .3rem;
			margin-left: .25rem;
			border: 1px solid $green;
			border-radius: 50%;
			padding: .5rem;
			transition: $slow;
			flex: 0 0 auto;
			&:before {
				@include size(2px, .5rem);
				content: "";
				display: block;
				background: $green;
				@include absolute(left 50% top 50%);
				transform: translateX(-50%) translateY(-50%);
				transition: $slow;
			}
			&:after {
				@include size(.5rem, 2px);
				content: "";
				display: block;
				background: $green;
				@include absolute(left 50% top 50%);
				transform: translateX(-50%) translateY(-50%);
				transition: $slow;
			}
		}
		&-title {
         		color: $primary;
         		transition: $bounce;
         		padding: .5rem .2rem;
         		display: block !important;
         		position: relative;
         		line-height: 1;	   
        			flex: 0 1 auto;
        			transition: $slow;
         		left: -.2rem;
         		&:hover {
         			color: $secondary;
         			left: 0rem;			
         		}
         		@include tablet {
         			font-size: 1.05rem;	
         			padding: .3rem .2rem;			
         		}
         		@include desktop {
         			font-size: 1.15rem;				
         		}					
		}
		&:hover {
			.accordion-menu-box-header {
				&-icon {
					background: $green;
					border-color: $green;
					&:before {
						background: $white;
					}
					&:after {
						background: $white;
					}
				}
				&-title {
					color: $green;
				}
			}
		}
	}
	&-content {
		max-height: 0px;
		overflow: hidden;
		transition: max-height 1s cubic-bezier(0.57,0.2,0.21,0.89);
		text-align: left;
		&-body {
			padding: .5rem 0 0;
			@extend %basic-text;
			font-size: 1rem;
		}
	}
	&.accordion-open-trigger {
		.accordion-menu-box {
			&-header {
				&-icon {
					background: $blue;
					border-color: $blue;
					&:before {
						background: $white;
						transform: translateX(-50%) translateY(-50%) rotate(90deg);
					}
					&:after {
						background: $white;
					}
				}
				&-title {
					color: $green;
				}
			}
			&-content {
				max-height: 3000px;
				display: block;
			}
		}
	}
}
