.basic-blocks {
  &.top_padding {
	  @extend %block-padding-top-small;
	}	
	&.bottom_padding {
	  @extend %block-padding-bottom-small;
	}	
	&.max_width {
  	 max-width: $desktop-medium;
	}	
}
