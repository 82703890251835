.amendments {
  &-list {
    text-align: left;
    margin: 0 auto 0 1rem;
    padding: 0;
    &-item {
  		@extend %slide-up;	
  		&.reveal {
    		@extend %slide-up-reveal;	
  		}      
      &-link {
        color: $primary;
        @extend %basic-text;
        transition: $slow;
        padding: .5rem;
        display: inline-table;
        line-height: 1.1;
        strong {
          text-decoration: uppercase;
          color: $gray;
        }
        &:hover {
          color: $secondary;
        }
      }
    }
    &.special {
      list-style: none;
      margin-left: 0;
      margin-bottom: .25rem;
      padding-left: 0;
      .amendments-list-item {
        margin-left: 0;
        padding-left: 1.05rem;
      }
    }
  }
	&-meta {
    width: 100%;
  	font-size: 80%;
  	margin-bottom: 1rem;
  	text-align: center;
  	@include tablet {
  		margin-bottom: 1.5rem;
  	}			
  	@include desktop {
  		margin-bottom: 2rem;
  	}
  	&-tag {
  	  font-weight: 700;
  	  font-size: 110%;
  	  text-transform: uppercase;
  	}
	}
	&-search {
	  background: $primary;
		flex: 0 0 100%;
		margin-bottom: 2rem;
		@include tablet {
			transition: $slow;	
		}		
		@include desktop {
  		margin-bottom: 3rem;
  		margin-top: 1rem;
		}	
		@include ie {
			flex: 0 0 100%;
		}		
		@keyframes border-pulse {
		  from { border: 1px solid $lightgray; }
		  50%  { border: 1px solid $darkgreen; }
		  to   { border: 1px solid $lightgray; }
		}		
		&-form {
			background: $primary;
			width: 100%;
			display: flex;
			padding: 0 .5rem 0 0;
			transition: $slow;
			cursor: pointer;
			@include tablet {
				background: transparent;
				border: 1px solid rgba($white, .3);
			}				
			&:hover {
				background: $darkgreen;
				border: 1px solid rgba($white, .7);				
			}	
			&.search-active {
				background: $darkgreen;
				animation-name: border-pulse;
				animation-duration: 1s;		
				animation-iteration-count: infinite;
			}				
		}
		&-label {
			@include screen-reader-text;
		}
		&-field {
			background: transparent;
			padding: 1rem;
			font-size: 1.3rem;
			border: 0;
			width: 100%;
			color: $white;		
			transition: $slow;
			@include placeholder {
				color: $white;
				transition: $slow;
			}
			&:hover {
				@include placeholder {
					color: $white;
				}
			}
			&:focus {
				@include placeholder {
					color: $white;
				}
			} 
		}	
		&-button {
			flex: 0 0 2.5rem;			
			border: 0;
			background: transparent;
			cursor: pointer;
			transition: $slow;
			padding: 1.1rem .75rem 1rem;
			&-svg {
				width: 100%;
				max-height: 1.5rem;
				fill: $white;
				transition: $slow;
			}
			&:hover {
				&-svg {
					fill: $darkgreen;
				}				
			}
			&:focus {
				&-svg {
					fill: $secondary;
				}					
			}			
		}		
	}	
}
body.convention {
  .grid-layer-col {
    margin: 1rem auto;
    @include desktop {
      margin: 0 auto;
    }
  }
}
