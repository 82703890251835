.photo-gallery {
  max-width: $desktop-medium;
  @extend %clearfix;
  @extend %block-padding-top-small;
  @extend %block-padding-bottom-small;
  margin-left: auto;
  margin-right: auto;
 	&.no_top_padding {
 	  padding-top: 0 !important;
 	}	
 	&.no_bottom_padding {
 	  padding-bottom: 0 !important;
 	}	
 	&.top_margin {
 	  @extend %block-margin-top;
 	}	
 	&.bottom_margin {
 	  @extend %block-margin-bottom;
 	}		
  &.full-width {
    max-width: 100%;
    .photo-gallery {
      &-item {
        width: 25%;
      }
    }
    .photo-sizer {
      width: 25%;
    }         
  }
  .photo-sizer {
    width: 50%;
    @include desktop {
      width: 33.33%;
    }
  }   
  &-item {
    float: left;
    display: block;
  }
  &-img {
    width: 100%;
  }  
}



.blog-slideshow {
   &.photo_padding {
      .blog-slideshow-item {
          padding: .25rem;
          @include desktop {
             padding: .75rem;
          }
      }
   }
   &-container {
      position: relative;
   }
   &-item {
      &-aspect {
         @include aspect(15,10);
         position: relative;
      }
      &-img {
         @include absolute(left 0 bottom 0);
         @include size(100%);
         object-fit: cover;
         object-position: center center;
      }
      &-caption {
        background: rgba($black, .5);
        @include absolute(left 0 bottom 0);
        z-index: 100;
        padding: .5rem .75rem;
        color: $white;
        font-size: .7rem;
        line-height: 1;
        text-align: left;
        @include desktop {
           font-size: .9rem;
        }
      }
   }
    &-controls {
        display: inline-flex;
        &-btn {
            background: transparent;
            @include size(3rem);
            border-radius: 50%;
            padding: .5rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);            
            border: none;
            outline: none;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-content: stretch;
            align-items: center;
            cursor: pointer;
            z-index: 120;
            transition: .3s all $slow-curve;
            &-svg {
                @include size(50%, auto);
                fill: $white;
                transition: .3s fill $slow;
            }
            &:hover {
                background: rgba($darkblue,.5);
                .blog-slideshow-controls-btn-svg {
                    fill: $white;
                }
            }
            &:focus {
                background: rgba($darkblue,.75);
            }            
        }
        &-next {
           left: .5rem;
           @include desktop {
             left: 1rem;
           }         
        }
        &-back {
           right: .5rem;
           @include desktop {
             right: 1rem;
           }
        }
    }   
}
.gallery-grid {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: center;
   align-content: stretch;
   align-items: flex-start;
   &.photo_padding {
      .gallery-grid-item {
          padding: .25rem;
          @include desktop {
             padding: .75rem;
          }
          &:first-of-type {
             padding-left: .5rem;
             @include desktop {
                padding-left: 1.5rem;
             }
          }
          &:last-of-type {
             padding-right: .5rem;
             @include desktop {
                padding-right: 1.5rem;
             }
          }          
      }
   }
   &-item {
      flex: 1 auto;
      &-aspect {
         @include aspect(15,10);
         position: relative;
      }
      &-img {
         @include absolute(left 0 bottom 0);
         @include size(100%);
         object-fit: cover;
         object-position: center center;
      }
   }
}
.parallax-photo {
    position: relative;
    height: 90vh;
    &-img {
       @include absolute(left 0 bottom 0);
       @include size(100%);
       object-fit: cover;
       object-position: center center;
    }
}

.photo-gallery {
    &-list {
       padding-top: 1rem;
       @include desktop {
          padding-top: 2rem;
       }
       &.photo_padding {
          .photo-gallery-link {
             padding: .25rem;
             width: calc(50% - .5rem);
             @include desktop {
                padding: .5rem;
                width: calc(33.33% - 1rem);
             }
          }
       }
    }
 
}


