.intro {
  max-width: $tablet;
  text-align: center;
  margin: 0 auto;
	@extend %block-padding-small;
	font-size: 1.1rem;
	line-height: 1.4;
	@extend %slide-up;	
	@include tablet {
  	font-size: 1.2rem;
	}		
	@include desktop {
  	font-size: 1.3rem;
	}
	@include lg {
  	font-size: 1.4rem;
	}		
	&.no_bottom_padding {
	  padding-bottom: 0;
	}
	&.reveal {
		@extend %slide-up-reveal;	
	}	
	p {
	  margin-bottom: 1rem;
	  &:last-of-type {
	    margin-bottom: 0;
	  }
	}
}
