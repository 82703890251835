.jobs-affiliate-promo {
 	padding: 1rem;
 	background: $blue;
 	margin-bottom: 1rem;
 	@include tablet {
 	  padding: 1.5rem;
   	margin-bottom: 1.5rem;
 	}
 	@include desktop {
 	  padding: 2rem;
   	margin-bottom: 2rem;
 	} 	
 	&-logo {
 	 max-width: 100%;
 	 
 	}
 	&-links {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
   	margin-top: 1rem;
   	@include tablet {
     	margin-top: 1.25rem;
   	}
   	@include desktop {
     	margin-top: 1rem;
   	} 	
 	}
 	&-button {
 	  border-radius: .75rem;
 	  background: $green;
 	  padding: .75rem 1.25rem;
 	  margin-right: .5rem;
 	  transition: $bounce;
 	  color: $white;
 	  text-transform: uppercase;
 	  font-family: $headline;
 	  font-weight: 600;
 	  overflow: hidden;
	  	box-shadow: $button-shadow;
	  	position: relative;
   	&:before,
   	&:after {
    		@include size(100%,50%);
    		display: block;
    		content: "";
    		z-index: 10;
    		transition: $bounce;
    		opacity: .4;
   	}
   	&:before {
    		@include absolute(top 0 left 0);
    		background: linear-gradient(0deg, rgba($white,0) 0%, rgba($white,.5) 100%);		
   	}
   	&:after {
    		@include absolute(bottom 0 left 0);
    		background: linear-gradient(0deg, rgba($black,.5) 0%, rgba($black,0) 100%);
   	}
   	&-text {
    		position: relative;
    		z-index: 200;
   	}	  
 	  &:hover {
 	    background: $white;
 	    color: $green;
 	    transform: scale(1.05);
 	  }
 	}
 	&-icon {
 	  @include size(3rem);
 	  padding: .75rem;
 	  display: block;
	  	transition: $bounce;
 	  &-svg {
 	    width: 100%;
 	    fill: $green;
	     transition: $bounce;
	     filter: drop-shadow(.15rem .2rem .25rem rgba(0,0,0,0.15));
 	  }
 	  &:hover {
 	    transform: scale(1.1);
 	    .jobs-affiliate-promo-icon-svg {
 	      fill: $white;
 	    }
 	  }
 	}
}
