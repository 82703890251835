.state {
  &-header {
		background: $secondary;
		color: $white;
		font-family: $headline;
		padding: .75rem .75rem;
		text-transform: uppercase;
		margin-bottom: .5rem;
		text-align: left;
		@extend %slide-up;	
		@extend %headline6;	
		&.reveal {
			@extend %slide-up-reveal;	
		}
		&.green {
		  background: $primary;
		}
		&.gray {
		  background: $gray;
		}		
  }
  &-section {
    @include tablet {
      margin-bottom: 1rem;
    }
    &-grid {
      margin: 0 auto;
  		@include tablet {
  			width: 100%;
  			display: flex;
  			flex-wrap: wrap;
  			justify-content: flex-start;
  			align-items: flex-start;
  			align-content: flex-start;
  		}    
    }
    &-item {
      position: relative;
      text-align: left;
  		@extend %slide-up;	
  	  padding: .5rem;
  		@include phone {
  		  &:last-of-type {
  		    margin-bottom: 0;
  		  }
  		}
  		@include tablet {
  		  width: 50%;
  		  padding: .75rem .75rem 0;
  		  margin-bottom: .5rem;
  		}
  		@include desktop {
  		  padding: 1rem 1rem 0;
  		  margin-bottom: 1rem;
  		}			
  		@include lg {
  		  padding: 1.25rem 1.25rem 0;
  		  width: 33%;
  		  margin-bottom: 1.5rem;
  		}		
  		&.wide {
  		  width: 100%;
  		}
  		&.reveal {
  			@extend %slide-up-reveal;	
  		}	    
      &-name {
        margin-bottom: .5rem;
    		@include tablet {
          font-size: 1rem;
    		}
    		@include desktop {
          font-size: 1.1rem;
    		}			
    		@include lg {
          font-size: 1.2rem;
    		}	    
      }
      &-details {
        text-align: left;
        p {
          margin-bottom: .5rem;
        }
        a {
          color: $primary;
          transition: $slow;
          &:hover {
            color: $secondary;
          }
          &:focus {
          	box-shadow: 0 0 0 1px $green;
          }
        }
      }
    }
  }
}
.hero-state {
  background-size: auto 120%;
  background-position: center center;
  background-repeat: no-repeat;
  @include tablet {
    background-position: right center;
  }
  .hero-text {
    max-width: none !important;
  }
  &-dropdown {
    background: $white;
    color: $gray;
    font-family: $headline;
    padding: .5rem;
    font-size: 90%;
    position: relative;
    z-index: 300;
    text-transform: uppercase;
    transition: $slow;
    border: 0;
    cursor: pointer;
    margin-top: -1rem;
		@extend %slide-up;	
		&.reveal {
			@extend %slide-up-reveal;	
		}    
    @include lg {
    	@include absolute(top 50% right 2rem);
    	translate: transformY(-50%);
    }
    &:hover {
      background: $secondary;
      color: $white;
    }
    &:focus {
    	box-shadow: $button-shadow-hover, 0 0 0 4px $secondary;
      background: $white;
      color: $secondary;
    }  
  }
}
