.text-layer {
	text-align: left;
	margin: 0 auto;
	@extend %block-padding;	
	opacity: 0;
	transition: $slow;
	@extend %basic-text;
	@extend %clearfix;	
	position: relative;
	@include phone {
	  padding-top: 1.5rem;
	  padding-bottom: 1.5rem;
	}
 &.remove_bottom_padding {
    padding-bottom: 0 !important;
 }
	&.small-padding {
  	@extend %block-padding-small;	
	}
	&.center, &.center_contents {
	  text-align: center;
	}
	&.no_top_padding {
	  padding-top: 0 !important;
	}	
	&.no_bottom_padding {
	  padding-bottom: 0 !important;
    p:last-of-type {
     margin-bottom: 1rem;
    	@include tablet {
  			margin-bottom: 1.5rem;	
    	}
    	@include desktop {
  			margin-bottom: 1.4rem;	
    	}	
    	@include md {
  			margin-bottom: 1.6rem;	
  		
    	}		
    	@include lg {
  			margin-bottom: 1.8rem;	
    	}			
    	@include xl {
  			margin-bottom: 2rem;	
    	}		
    }
	}	
	&.top_margin {
	  @extend %block-margin-top;
	}	
	&.bottom_margin {
	  @extend %block-margin-bottom;
	}			
	&.dark-gray {
	  background: $darkgray;
	  color: $white;
  	h2, h3, h4, h5, h6 .text-layer-headline, a {
  		color: $white;
  	}
  	a:hover {
  	  color: $white;
  	  text-decoration: underline;
  	}
	}
	&.black {
	  background: $black;
	  color: $white;
  	h2, h3, h4, h5, h6 .text-layer-headline, a {
  		color: $white;
  	}
  	a:hover {
  	  color: $white;
  	  text-decoration: underline;
  	}  	
	}	
	&.blue {
	  background: $secondary;
	  color: $white;
  	h2, h3, h4, h5, h6 .text-layer-headline, a {
  		color: $white;
  	}	 
  	a:hover {
  	  color: $white;
  	  text-decoration: underline;
  	}  	
	}	
		&.dark-blue {
	  background: darken($darkblue, 8%);
	  color: $white;
  	h2, h3, h4, h5, h6 .text-layer-headline, a {
  		color: $white;
  	}	 
  	a:hover {
  	  color: $white;
  	  text-decoration: underline;
  	}  	
	}	
	&.green, &.dark-green {
	  background: $primary;
	  color: $white;
  	h2, h3, h4, h5, h6 .text-layer-headline, a {
  		color: $white;
  	}	  
  	a:hover {
  	  color: $white;
  	  text-decoration: underline;
  	}  	
	}
	&.dark-green {
	  background-color: $darkgreen;
	}
	&.light-gray, &.gray {
	  background: $lightestgray;
	  color: $gray;
  	h2, h3, h4, h5, h6 .text-layer-headline, a {
  		color: $primary !important;
  	}	  
  	a:hover {
  	  color: $secondary !important;
  	  text-decoration: underline;
  	}  	
	}
	&-wrap {
  	max-width: $tablet;
  	margin: 0 auto;
  	position: relative;
		@extend %clearfix;	
	  >*:last-child {
	    margin-bottom: 0;
	  }
	  .instagram-media {
	     margin-left: auto !important;
	     margin-right: auto !important;
	  }
	}
	&-headline {
		color: $primary;
		text-align: center;
		line-height: 1;
		margin-bottom: 1rem;
    @extend %headline2;
    @include tablet {
      margin-bottom: 1.5rem;
    }
    @include desktop {
      margin-bottom: 2rem;
    }   
    &.trigger {
      @extend %slide-up;
      &.reveal {
        @extend %slide-up-reveal
      }
    }
	}
	&-text {
	   @extend %basic-text;
	}
	h2 {
    @extend %headline2;
		color: $gray;
		margin-bottom: 1rem;
    line-height: 1.1;
    @include tablet {
      margin-bottom: 1.25rem;
    }
    @include desktop {
      margin-bottom: 1.5rem;
    }      
	}	
	h3 {
    @extend %headline5;
		color: $gray;
		margin-bottom: 1rem;
    line-height: 1.1;
    @include tablet {
      margin-bottom: 1.25rem;
    }
    @include desktop {
      margin-bottom: 1.5rem;
    }      
	}		
  h4 {
    @extend %headline4;
    color: $gray;
  }
  h5 {
    @extend %headline5;
    color: $gray;
  }
  h6 {
    @extend %headline6;
    color: $gray;
    margin-bottom: 1em;
  }
	&.reveal {
		opacity: 1;				
	}
	a {
	  color: $primary;
	  transition: $basic;
	  font-weight: 700;
	  display: inline;
	  &:hover {
	    color: $secondary;
	  }
	}
	.button-block {
	  margin-top: 1rem;
	}
	
  ul, ol {
    margin-bottom: 1rem;
    @include tablet {
      margin-bottom: 1.5rem;
    }
    @include desktop {
      margin-bottom: 2rem;
    }    
  }
  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .disclaimer {
    font-size: .8rem;
    text-align: center;
    margin-top: 1rem;
    display: block;
    @include tablet {
      margin-top: 1.5rem;
    }
    @include desktop {
      margin-top: 2rem;
    }       
  }  
  .video-player {
    margin: 0 auto;
    margin-bottom: 1rem;
    @include tablet {
      margin-bottom: 1.25rem;
    }
    @include desktop {
      margin-bottom: 1.75rem;
    }   
    @include lg {
      margin-bottom: 2rem;
    }     
  }
  &-image {
    position: relative;
    width: 100%;
    @extend %basic-text;
    &-src {
      width: 100%;
      vertical-align: middle;
    }
    &.full-width {
      margin: 0 auto;
      margin-bottom: 1rem;
      max-width: $tablet;
      @include tablet {
        margin-bottom: 1.5rem;
        margin-top: 2rem;
      }
      @include desktop {
        margin-top: 3rem;
      }   
      @include xl {
        margin-top: 4rem;
      }        
    }
    &.floating-image {
      max-width: 100%;
      margin-bottom: 1rem;
      @include tablet {
        max-width: 50%;
        margin-bottom: 0;
        &.floating-image-left {
          float: left;
          margin: 0 1rem 1rem 0;
          @include tablet {
            margin: 0 1.5rem 1rem 0;
          }
          @include desktop {
            margin: 0 2rem 1rem 0;
          }          
        }        
      }
      @include tablet {
        max-width: 50%;
        &.floating-image-right {
          float: right;
          margin: 0 0 1rem 1rem;
          @include tablet {
            margin: 0 0 1rem 1.5rem;
          }
          @include desktop {
            margin: 0 0 1rem 2rem;
          }              
        } 
      }    
      &.intrinsic {
       max-width: none;
       width: auto;
       text-align: center;
       @include phone {
        float: none !important;
       }
       @include tablet {
        display: table !important;
       }
       .text-layer-image {
        &-src {
         margin: 0 auto;
         width: auto !important;
         max-width: 100%;
        }        
        &-caption {
         @include tablet {
          display: table-caption;
          caption-side: bottom;
          text-align: left;
         }
        }
       }
      }
    }
    &-caption {
     font-size: 70%;
     line-height: 1;
     padding: .25rem;
   		color: $gray;
   		@include desktop {
       padding: .25rem 0;
   		}
    }
  }
  &-embed {
    max-width: 100%;
    margin-bottom: 1rem;
    padding: 1rem;
   	opacity: 0;
   	transition: $slow;
    @include tablet {
      width: 40%;
      float: right;
      margin: 0 0 1rem 1.5rem;
    }
    @include desktop {
      width: 50%;
      margin: 0 0 1rem 2rem;
    }
    &-headline {
      margin-bottom: .5rem;
      font-family: $headline;
      font-weight: 600;
    }
    &.reveal {
      opacity: 1;
    }
  	&.dark-gray {
  	  background: $darkgray;
  	  color: $white;
    	figcaption{
    		color: $white;
    	}
  	}
  	&.black {
  	  background: $black;
  	  color: $white;
    	figcaption {
    		color: $white;
    	}
  	}	
  	&.blue {
  	  background: $secondary;
  	  color: $white;
    	figcaption {
    		color: $white;
    	}	 
  	}	
  	&.green {
  	  background: $primary;
  	  color: $white;
    	figcaption {
    		color: $white;
    	}	  
  	}
  	&.light-gray, &.gray {
  	  background: $lightestgray;
  	  color: $gray;
    	figcaption {
    		color: $primary !important;
    	}	  
  	}    
  }
}
.open-text {
 margin: 0 auto;
 &.not_full_width {
  max-width: $desktop;
 }
}
.blog-post {
  .text-layer {
    &:first-of-type {
      	@include desktop {
      		padding-top: 2rem;		
      	}
      	@include md { 
      		padding-top: 3rem;		
      	}	
      	@include lg { 
      		padding-top: 3.5rem;		
      	}
      	@include xl { 
      		padding-top: 4rem;		
      	}		
    }
  }
}
