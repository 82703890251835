.mobilize-promo {
  background-color: $blue;
 	box-shadow: $shadow;
 	position: relative;
 	transition: $slow;
 	overflow: hidden;
 	@extend %block-padding;
 	&.add_top_padding {
   	margin-top: 1.5rem;
   	@include tablet { 
   		margin-top: 2rem;		
   	}
   	@include desktop {
   		margin-top: 4rem;		
   	}
   	@include md { 
   		margin-top: 5rem;				
   	}	
   	@include lg { 
   		margin-top: 6rem;						
   	}
   	@include xl { 
   		margin-top: 7rem;								
   	}		
 	}
 	&.not_full_width {
 	 max-width: $max-width;
 		margin: 1rem 1rem 0;
 		@include tablet {
   		margin: 2rem auto;
 		}
 	}
 	&.reveal {
 	}
 	&-text {
 	  max-width: $tablet;
 	  color: $white;
 	  position: relative;
 	  z-index: 100;
 	  margin: 0 auto;
  		@extend %slide-up;
  		&.reveal {
    		@extend %slide-up-reveal;
  		}
 	}
 	&-title {
  		font-family: $headline;
  		margin: 0 auto 1rem;
  		color: $white;
  		@extend %headline2;
  		@include desktop {
   			margin: 0 auto 1.25rem;			
  		}			
  		&-link {
   			color: $white;
   			transition: $slow;
   			padding: 0 .5rem;
   			&:hover {
   				color: $primary;
   			}
   		}
 	}
 	&-teaser {
 	  color: $white;
 	  font-family: $body;
 	  font-size: 1.1rem;
 	  @include desktop {
 	    font-size: 1.3rem;
 	  }
 	}
 	&-select {
 	  background: white;
 	  border: 1px solid darken($darkblue,20%);
 	  font-size: 1.2rem;
 	  color: $darkblue;
 	  padding: 1rem;
 	  width: 100%;
 	  max-width: 20rem;
 	  margin: 1rem auto 0;
 	  cursor: pointer;
	   transition: $slow;
 	  @include desktop {
 	    margin-top: 2rem;
 	    font-size: 1.3rem;
 	  }
 	  &:hover {
 	    background: darken($darkblue,20%);
   	  border: 1px solid darken($darkblue,30%);
   	  color: $white;
 	  }
 	  &:focus {
 	    background: $green;
   	  border: 1px solid darken($darkblue,20%);
   	  color: $white;
 	  } 	 
 	  &-form {
 	    margin-bottom: 1rem;
 	  }
 	}
 	&-background {
 	  background-repeat: no-repeat;
 	  background-size: auto 130%;
 	  background-position: center center;
 	  @include size(100%);
 	  @include absolute(left 0 top 0);
 	  z-index: 1;
 	  opacity: 0;
 	  transition: $slow;
 	  &.trigger {
 	    opacity: .7;
 	  }
 	}
}
