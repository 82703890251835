.history {
  &-group {
    text-align: left;
    &-headline {
  		@extend %headline2;
  		color: $gray;
  		margin-bottom: 1rem;
  		@extend %slide-up;
      @include tablet {
    		margin-bottom: 1.5rem;
      }   
      @include lg {
    		margin-bottom: 2rem;
      }        
  		&.reveal {
    		@extend %slide-up-reveal;
  		}  		
    }
    &-photo {
  		@extend %slide-up;
  		&.reveal {
    		@extend %slide-up-reveal;
  		}      
      @include tablet {
        width: 100%;
        margin: 0 0 1rem;
      }  
    }
  }
  &-item {
		padding: .5rem 0;			
		display: flex;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-content: flex-start;	
		@extend %slide-up;
		&.reveal {
  		@extend %slide-up-reveal;
		}
    &-photo {
      width: 100%;
      margin: 1rem auto;
      @include tablet {
        max-width: 30%;
        float: right;
        margin: 0 0 1rem 1rem;
      }
  		@extend %slide-up;
  		&.reveal {
    		@extend %slide-up-reveal;
  		}      
    }
    &-year {
      width: 6rem;
  		@extend %headline5;
  		color: $primary;
  		padding-right: 1rem;
  		@include tablet {
        width: 8rem;
  		}
  		@include desktop {
        width: 9rem;
  		}  		
    }
    &-details {
      @extend %basic-text;
      flex: 1;
      a {
        color: $primary;
        transition: $slow;
        &:hover {
          color: $secondary;
        }
      }
    }      
  }
}
