.body-form {
    z-index: 100;
    position: relative;

    &.long {
        transition: none;
    }
    &-large-label {
      text-align: center;
      margin: 1rem auto 1rem !important;
      width: 100%;
      @extend %headline3;
      color: $gray;
      display: block;
      em {
         display: block;
         font-size: 60%;
         font-weight: 300 !important;
         font-style: normal;
      }
    }
    &-grid {
        width: 100%;
        @include tablet {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: stretch;
            align-content: stretch;
            margin-bottom: 1rem;
        }

        &-masonry {
            flex-wrap: wrap;
        }
    }
    &-row {
        @include tablet {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: stretch;
            align-content: stretch;
            margin-bottom: 1rem;
        }
    }
    &-masonry {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-end;
        align-content: flex-end;
        margin-top: .5rem;

        .body-form-field {
            width: 100%;
            margin: .5rem 0;

            @include tablet {
                width: 50%;

                &.wide {
                    width: 100%;
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }

                margin: 1rem 0;

                &:nth-child(even) {
                    padding-left: .75rem;

                    div.error {
                        left: .75rem;
                    }

                    .body-form-label.hidden-label {
                        left: calc(.75rem + 1px);
                    }
                }

                &:nth-child(odd) {
                    padding-right: .75rem;
                }
            }
        }

        &.fours {
            .body-form-field {
                width: 50%;

                &:nth-child(even) {
                    padding-left: .25rem;
                }

                &:nth-child(odd) {
                    padding-right: .25rem;
                }

                @include tablet {
                    width: 25%;

                    &:nth-child(4n+1) {
                        padding-right: .75rem;
                    }

                    &:nth-child(4n+2),
                    &:nth-child(4n+3) {
                        padding-left: .3725rem;
                        padding-right: .3725rem;
                    }

                    &:nth-child(4n+4) {
                        padding-left: .75rem;
                    }
                }
            }
        }
    }

    &-col {
        position: relative;
        color: $white;
        text-align: left;

        @include tablet {
            flex: 1;
            padding: 1rem;
        }

        &.nopad {
            @include tablet {
                padding: 0;

                .body-form-field {
                    &:first-of-type {
                        margin-top: 0;
                    }
                }
            }
        }

        &.highlighted {
            background: $lightestgray;
            border: 1px solid $lightgray;
            padding: 1rem 1.5rem;

            @include tablet {
                margin-left: 1rem;
                padding: 1.25rem 2rem;
            }

            @include desktop {
                margin-left: 1.5rem;
            }
        }

        .body-form-field {
            &:last-of-type {
                @include tablet {
                    margin-bottom: 0;
                }
            }
        }
    }

    &-grouping {
        padding: 0 0 1rem;

        @include tablet {
            padding: 0 0 1.5rem;
        }

        @include desktop {
            padding: 0 0 2rem;
        }

        &:last-of-type {
            padding: 0 !important;
        }

        &-heading {
            @extend %headline5;
            margin-bottom: .5rem;
        }

        &.highlighted {
            background: $lightestgray;
            border: 1px solid $lightgray;
            padding: 1.5rem 2rem;
            text-align: center;
        }
    }

    &-field {
        padding: 0 0;
        margin: 1rem 0;
        position: relative;
        text-align: center;
        &:last-of-type {
            @include tablet {}
        }

        &.text-entered {
            .body-form-label {
                opacity: 1;
                visibility: visible;
            }
        }

        div.error {
            background: $red;
            padding: .15rem;
            font-size: 70%;
            color: $white;
            display: inline-block;
            @include absolute(bottom 0 left 0);
            transform: translateY(-0%);
        }
    }

    &-label {
        background: $white;
        padding: .25rem;
        font-size: 80%;
        @include absolute(top 0 left 1px);
        transform: translateY(-50%);
        display: block;
        color: $gray;
        line-height: 1;
        cursor: pointer;
        transition: $slow;
        opacity: 0;
        visibility: hidden;
        text-align: center;
        &:hover {
            color: $primary;
        }

        &.revealed {
            font-size: 100%;
            position: relative;
            top: auto;
            left: auto;
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
            padding-left: 0;
        }

        small {
            display: block;
        }

        a {
            color: $primary;
            transition: $slow;

            &:hover {
                background: $secondary;
            }
        }
    }

    &-input,
    &-textarea,
    &-select {
        font-family: $body;
        width: 100%;
        background: $white;
        border: 1px solid $lightgray;
        color: $gray;
        padding: 1rem;
        @extend %basic-text;
        transition: $bounce;
        cursor: pointer;

        &:hover {
            border-color: $primary;
            color: $primary;

            @include placeholder {
                color: $primary;
            }
        }

        &:focus {
            background: $white;
            border-color: $gray;
            color: $secondary;

            @include placeholder {
                color: $secondary;
            }
        }

        &.error {
            border-color: $primary;
        }
        &.highlighted {
          border: 5px solid $gray;
          font-size: 2.5rem;
          text-align: center;
          letter-spacing: 30px;
        }
    }
    &-input {
       &.small {
          font-size: 1rem !important;
          padding: .25rem .5rem !important;
       }
    }
    &-textarea {
        height: 10rem;
    }
    &-checkbox {
       width: calc(50% - 1rem);
       @include desktop {
          width: calc(33.33% - 1rem);
       }       
       &-group {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-content: stretch;
          align-items: center;
          gap: 1rem;
          margin: 1rem auto;
          @include desktop {
             margin: 2rem auto;
          }
       }
       &-input {
         @include screen-reader-text;
         opacity: 0;
         visibility: hidden;
         &:checked ~ .body-form-checkbox {
             &-box {
                background-color: $green !important;
                border-color: $darkgreen !important;
                &:before {
                   opacity: 1;
                }                
             }   
             &-text {
                color: $green !important;
             }
         }
       }
       &-label {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-content: stretch;
          align-items: center;
          gap: .5rem;
          &:hover {
             .body-form-checkbox-box {
                border-color: $green;
             }
             .body-form-checkbox-text {
                color: $green;
             }
          }
       }
       &-box {
          @include size(1.5rem)
          border: 2px solid $lightgray;
          position: relative;
          overflow: hidden;
          transition: .3s all $slow-curve; 
          border-radius: .2rem;
          background-color: $lightestgray;
          &:before {
             background-image: url("data:image/svg+xml, %3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M432 64l-240 240-112-112-80 80 192 192 320-320z'%3E%3C/path%3E%3C/svg%3E%0A");
             @include size(80%);
             background-size: cover;
             background-repeat: no-repeat;
             background-position: center center;
             @include absolute(left 50% top 50%);
             transform: translate(-50%, -50%);
             z-index: 100;
             display: block;
             content: "";
             opacity: 0;
             transition: .3s all $slow-curve; 
          }
       }
       &-text {
          @extend %basic-text;
          transition: .3s all $slow-curve; 
       }
    }
    &-boolean {
       width: calc(50% - 1rem);
       &-group {
          display: inline-flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-content: stretch;
          align-items: center;
          gap: 1rem;
          margin-top: 1rem;
       }
       &-input {
         @include screen-reader-text;
         opacity: 0;
         visibility: hidden;
         &:checked ~ .body-form-boolean {
            &-button {
               background: $green !important;
               color: $white !important;
            }
         }
       }
       &-button {
          background: $lightestgray;
          display: block;
          padding: 1.25rem;
          border-radius: .5rem;
          width: 6rem;
          transition: .3s all $slow-curve; 
          &:hover {
             background-color: $blue;
             .body-form-boolean-text {
                color: $white;
             }
          }           
       }       
       &-text {
          @extend %headline4;
          text-transform: uppercase;
          transition: .3s all $slow-curve; 
       }
    }
        
    &-radio {
        width: 100%;
        &-item {
            padding: .35rem;
            position: relative;
            
        }
        &-input {
            opacity: 0;
            visibility: hidden;
            height: 0;
            width: 0;

            &:hover {
                .body-form-radio-button {
                    background-color: $primary;
                }
            }

            &:checked+.body-form-radio-label {
                color: $primary;

                &:after {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        &-label {
            cursor: pointer;
            transition: $slow;
            display: block;
            padding-left: 2.3rem;
            position: relative;
            text-align: left;

            &:hover {
                color: $primary;

                &:before {
                    box-shadow: .1rem .1rem .5rem 0 rgba(0, 0, 0, .1);
                }
            }

            &:before,
            &:after {
                content: "";
                display: block;
                border-radius: 50%;
                transform: translateY(-50%);
                transition: $bounce;
            }

            &:before {
                @include absolute(left .5rem top 50%);
                @include size(1.5rem);
                background-color: $white;
                border: 1px solid $lightgray;
            }

            &:after {
                background: $primary;
                @include absolute(left 1.25rem top 50%);
                @include size(.75rem);
                transform: translateX(-50%) translateY(-50%);
                opacity: 0;
                visibility: hidden;
                border-radius: 50%;
                z-index: 100;
            }
        }
    }

    &-button {
        &-row {
            padding: 2rem 0 1rem;
            text-align: center; 
            width: 100%;
            @include tablet {
                padding: 1rem 0 0;
            }

            @include tablet {
                padding: 1rem 0 0;
            }
        }

        font-family: $headline;
        background: $primary;
        color: $white;
        padding: 1rem 1.75rem;
        border: none;
        margin: 0 auto;
        text-transform: uppercase;
        @extend %headline6;
        cursor: pointer;
        transition: $bounce;

        &:hover {
            background: darken($primary, 20%);
            color: $white;
            transform: scale(1.05);
        }

        &:active {
            background: darken($primary, 30%);
            transform: scale(.95);
        }
    }

    &-disclaimer {
        padding-top: 1rem;
        font-size: 80%;
        text-align: center;
        color: $gray;
        max-width: $tablet;
        margin: 0 auto;
        text-align: center;
        line-height: 1 !important;
        display: block;
        @include tablet {
            padding-top: 1.5rem;
        }

        @include tablet {
            padding-top: 2rem;
        }

        a {
            color: $darkgray;
            transition: $slow;

            &:hover {
                color: $primary;
            }
        }
    }

    &-thanks {
        @extend %block-padding-small;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        @include absolute(top 0 right 0);
        transition: $slow;
        transition-delay: .4s;
        z-index: 1;
        text-align: center;
        @extend %basic-text;

        h2,
        h3 {
            @extend %headline2;
            color: $gray;
            margin-bottom: 1rem;
        }

        &.long {
            opacity: 1;
            visibility: visible;
            @include relative(top auto right auto);
            transition: none;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &-container {
        @extend %block-padding-small;
        max-width: $desktop;
        margin: 0 auto;
        text-align: left;
        transition: $slow;
        position: relative;
        @extend %slide-up;

        &.no_top_padding {
            padding-top: 0;
        }

        &.reveal {
            @extend %slide-up-reveal;
        }

        &.submitted {
            .body-form {
                opacity: 0;
                visibility: hidden;
                z-index: 1;
                height: 10rem;

                &-thanks {
                    opacity: 1;
                    visibility: visible;
                    z-index: 100;
                }
            }
        }
    }

    .g-recaptcha {
        margin: 1rem auto .75rem;
        text-align: center;
        display: block;

        @include desktop {
            margin: 2rem auto 1.25rem;
        }

        div {
            margin: 0 auto;
        }
    }
}
.union-card {
   .body-form {
       &-input,
       &-textarea,
       &-select {
           background: $lightestgray;
           border: 1px solid $lightgray;
           &:hover {
              border-color: $green;
           }
           &:focus {
              color: $blue;
              background-color: $white;
           }
       }
   }
   &-assistance {
     background: $lightgray;
     padding: 1rem;
     font-size: 1rem;
     display: table;
     margin: 0 auto;
     a {
     }
   }
}
