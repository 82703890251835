.bio {
  &-headshot {
    width: 15rem;
    max-width: 175px;
    margin: 0 0 1rem 0;
    border-radius: 50%;
		@include tablet {
      margin: 0 1.5rem 0 0;
		}    
  }
  &-meta {
		max-width: $max-width-text;	
		text-align: center;
		margin: 0 auto 1rem auto;
		@extend %slide-up;
		@include tablet {
  		display: flex;
  		flex-direction: row;
  		flex-wrap: wrap;
  		justify-content: center;
  		align-content: stretch;
  		align-items: center;			  
		}
		&.with-padding {
		  @extend %block-padding-top-small;	
		  @extend %block-padding-bottom-small;	
		}
		&.reveal {
			@extend %slide-up-reveal;		
		} 		
		@include tablet {
		}		
		@include desktop {
			margin: 0 auto 1.25rem auto;				
		}	
		@include lg {
			margin: 0 auto 1.5rem auto;				
		}	    
    a {
      color: $primary;
      transition: $slow;
      &:hover { 
        color: $secondary;
      }
    }
    &-text {
  		@include tablet {
        text-align: left;
  		}
    }
  }
	&-headline {
		color: $gray;
		line-height: 1;
		margin: 0 auto;
		transition-delay: .2s !important;
		@extend %headline2;
	}		 
	.bio-location,
	.bio-title {
	  @extend %headline5;
	  font-family: $body;
	  color: $gray;
	}
	.bio-text {
		@extend %basic-text;
		@extend %slide-up;
		&.reveal {
			@extend %slide-up-reveal;		
		}  	
	}
}
