@import "global/globals";  
@import "pages/hero";   
@import "pages/current-section-nav";   
@import "pages/menu-box";   
@import "pages/advantage";   
@import "pages/basic-text"; 
@import "pages/meta";   
@import "pages/text-layer";   
@import "pages/difference";   
@import "pages/fixed-join";   
@import "pages/related-news";   
@import "pages/banner-buttons";   
@import "pages/jobs";   
@import "pages/retirees";   
@import "pages/video";   
@import "pages/map";   
@import "pages/grids";   
@import "pages/sidebar-banner";   
@import "pages/press";   
@import "pages/leadership";   
@import "pages/bio";   
@import "pages/news-list";   
@import "pages/intro";   
@import "pages/latest-feed";   
@import "pages/states";   
@import "pages/child-list";   
@import "pages/issues";   
@import "pages/history";   
@import "pages/sidebar-list";   
@import "pages/careers";  
@import "pages/pagination";
@import "pages/forms";
@import "pages/leg-reports";
@import "pages/scorecard";
@import "pages/amendments";
@import "pages/basic-blocks";
@import "pages/share";
@import "pages/photo-gallery";
@import "pages/email-layer";
@import "pages/accordion";
@import "pages/accordion-menu-box";
@import "pages/mobilize-promo";
@import "pages/victory";
@import "pages/jobs-affilates";
@import "pages/student-debt-resources";
@import "pages/fixed-cta";

.basic-wrap {
	text-align: left;
	margin: 0 auto;
	@extend %block-padding-small;	
	transition: $slow;
	@extend %basic-text;	
	max-width: $tablet;
}
.page-take-action {
 iframe {
  width: 100%;
  height: 800px;
  border: 0;
 }
}
.anchored-content {
  display: block;
  position: relative;
  top: -120px; 
  height: 1px;
  visibility: hidden;  
  &:last-of-type {
     padding-bottom: 0;
  }
}
html {
    scroll-behavior: smooth;
    &:target {
      display: block;
      position: relative;
      top: -120px; 
      visibility: hidden;
    }
}
.remove_bottom_padding,
.no_bottom_padding {
    padding-bottom: 0 !important;
 }
.remove_top_padding,
.no_top_padding {
    padding-top: 0 !important;
 }
 
 .layer {
     background: $white;
     &.remove_bottom_padding {
        padding-bottom: 0 !important;
     }
    	&.small-padding {
      	@extend %block-padding-small;	
    	}
    	&.center, &.center_contents {
    	  text-align: center;
    	}
    	&.no_top_padding {
    	  padding-top: 0 !important;
    	}	
    	&.no_bottom_padding {
    	  padding-bottom: 0 !important;
        p:last-of-type {
         margin-bottom: 1rem;
        	@include tablet {
      			margin-bottom: 1.5rem;	
        	}
        	@include desktop {
      			margin-bottom: 1.4rem;	
        	}	
        	@include md {
      			margin-bottom: 1.6rem;	
      		
        	}		
        	@include lg {
      			margin-bottom: 1.8rem;	
        	}			
        	@include xl {
      			margin-bottom: 2rem;	
        	}		
        }
    	}	
    	&.top_margin {
    	  @extend %block-margin-top;
    	}	
    	&.bottom_margin {
    	  @extend %block-margin-bottom;
    	}			
    	&.dark-gray {
    	  background: $darkgray;
    	  color: $white;
      	h2, h3, h4, h5, h6 .text-layer-headline, a {
      		color: $white;
      	}
      	a:hover {
      	  color: $white;
      	  text-decoration: underline;
      	}
    	}
    	&.black {
    	  background: $black;
    	  color: $white;
      	h2, h3, h4, h5, h6 .text-layer-headline, a {
      		color: $white;
      	}
      	a:hover {
      	  color: $white;
      	  text-decoration: underline;
      	}  	
    	}	
    	&.blue {
    	  background: $secondary;
    	  color: $white;
      	h2, h3, h4, h5, h6 .text-layer-headline, a {
      		color: $white;
      	}	 
      	a:hover {
      	  color: $white;
      	  text-decoration: underline;
      	}  	
    	}	
    		&.dark-blue {
    	  background: darken($darkblue, 8%);
    	  color: $white;
      	h2, h3, h4, h5, h6 .text-layer-headline, a {
      		color: $white;
      	}	 
      	a:hover {
      	  color: $white;
      	  text-decoration: underline;
      	}  	
    	}	
    	&.green, &.dark-green {
    	  background: $primary;
    	  color: $white;
      	h2, h3, h4, h5, h6 .text-layer-headline, a {
      		color: $white;
      	}	  
      	a:hover {
      	  color: $white;
      	  text-decoration: underline;
      	}  	
    	}
    	&.dark-green {
    	  background-color: $darkgreen;
    	}
    	&.light-gray, &.gray {
    	  background: $lightestgray;
    	  color: $gray;
      	h2, h3, h4, h5, h6 .text-layer-headline, a {
      		color: $primary !important;
      	}	  
      	a:hover {
      	  color: $secondary !important;
      	  text-decoration: underline;
      	}  	
    	}  
 }

.floating-media {
   margin-bottom: 1rem;
   @include tablet {
      max-width: 50%;
      width: 400px;
      margin-bottom: 2rem;
   }
   &.left {
      @include tablet {
         float: left;
         margin-right: 2rem;
      }
   }
   &.right {
      @include tablet {
         float: right;
         margin-left: 2rem;
      }
   }  
   .tiktok-embed {
      margin: 0 auto !important;
      max-width: 375px !important;
   }
   .text-layer {
      background-color: transparent !important;
      padding: 0 !important;
   }
}

#countable_button {
   padding: 1.4rem;
}
