.press {
	&-meta {
	  width: 100%;
		font-size: 80%;
		margin-bottom: 1rem;
		@include tablet {
			margin-bottom: 1.5rem;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-content: flex-start;
			align-items: stretch;	
		}			
		@include desktop {
  		margin-bottom: 2rem;
		}	
		@include lg {
		}				
		&-immediate,
		&-date {
		  font-weight: bold;
		  text-transform: uppercase;
		}
		&-contact {
			@include phone {
  		  margin-top: 1rem;
			}
			@include tablet {
				text-align: right;
			}
			a {
			  display: inline-block;
			  color: $secondary;
			  transition: $slow;
			  &:hover {
			    color: $primary;
			  }
			}
		}
	}
	&-headline {
		font-family: $headline;
		font-size: 1.8rem;
		line-height: 1.1;
		color: $primary;
		margin-bottom: 1rem;
		@extend %headline2;	
	  &-link {
	    color: $gray;
	    transition: $slow;
	    &:hover {
	      color: $secondary;
	    }
	  }
	}
	&-description {
		font-style: italic;
	}
	&-footer {
		font-size: 70%;
		color: rgba($gray, .7);
		border-top: 1px solid $lightgray;
		padding: 1rem 0 0;
		margin: 1rem 0 0 0;
		@include tablet {
			padding: 1.25rem 0 0;
			margin: 2rem 0 0 0;			
		}	
		@include md {
			padding: 1.5rem 0 0;
			margin: 3rem 0 0 0;			
		}		
		&-description {}
		&-spacer {}
		&-address {}
	}
  &-list {
    max-width: $desktop;
    margin: 0 auto;
    @include desktop {
      margin-bottom: 2rem;
    }
    &-item {
      text-align: left;
  		@extend %slide-up;
      &.reveal {
    		@extend %slide-up-reveal;	
      }
      &:hover {
    		.press-list-item-headline {
    		  color: $secondary;
    		}
      }
      &-grid {
    			display: flex;
    			flex-wrap: nowrap;
    			align-content: flex-start;
    			align-items: stretch;			
          padding: 1rem;
      		@include tablet {
      		  padding: 1rem;
      		}
      		@include lg {
      		  padding: 1rem .5rem;
      		}     			
    			&:hover {
    			  .recent-press-item-headline {
  			      color: $secondary;
    			  }
    			}
          &:focus {
          	box-shadow: inset 0 0 0 2px $green;
          }    			
      }
      &-date {
        text-transform: uppercase;
        color: $gray;
  			padding: .25rem 1rem .25rem 0;
  			white-space: nowrap;
      }
      &-meta {
        color: $gray;
        opacity: .8;
    		line-height: 1.1;
    		text-transform: uppercase;
    		font-size: .9rem;
    		margin-top: .75rem;
      }      
      &-headline {
    		font-family: $headline;
    		line-height: 1.1;
    		color: $primary;
    		transition: $slow;
    		flex: 1;
    		@extend %headline4;	
      }
      &-description {
        color: $gray;
    		@extend %basic-text;
    		line-height: 1.2;
    		margin-top: .5rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;    		
        }      
    } 
    &.stacked {
      .press-list-item {
        &-grid {
          display: block;
        }
        &-headline {
          color: $secondary;
      		@extend %headline5;	
          &:hover {
            color: $primary;
          }
        }
      }
    }    
  }
  &-section-headline {
    text-align: left;
    color: $gray;
    margin-bottom: 1rem;
		@extend %headline3;
		@include tablet {
		  margin-bottom: 1.25rem;
		}
		@include lg {
		  margin-bottom: 1.5rem;
		}		
		@extend %slide-up;	
		&.reveal {
			@extend %slide-up-reveal;	
		}  		
  }
  .sidebar-banner {
    margin-bottom: 1rem !important;
    @include tablet {
      margin-bottom: 1.5rem !important;
    }
    @include lg {
      margin-bottom: 2rem !important;
    }
  }
}
.recent-press {
  background: $lightestgray;
	@extend %block-padding-small;
  @include phone {
    padding-bottom: 3rem;
  }	
  &-wrap {
    margin: 0 auto;

  }
  &-headline {
		@extend %headline3;
    margin: 1rem;
    color: $gray;
		@extend %slide-up;	
		&.reveal {
			@extend %slide-up-reveal;	
		}  	    
  }
  .press-list-item {
    &:last-of-type {
      padding-bottom: 0;
    }
    &-date {
			font-weight: bold;
    }
    &-headline {
  		@extend %headline4;
  		margin-bottom: 0 auto;
    }
  }
  .button-block {
    margin-top: 2rem;
  }
}
