.menu-box {
	@extend %slide-up;	
	&.reveal {
		@extend %slide-up-reveal;	
		@include tablet {
			&:nth-of-type(3n+1) {
				transition-delay: .2s;
			}
			&:nth-of-type(3n+2) {
				transition-delay: .4s;
			}	
			&:nth-of-type(3n+3) {
				transition-delay: .6s;
			}			
		}	
		&:hover {
		  transition-delay: 0s !important;
		}
	}		
	&-grid {
		max-width: $max-width;
		width: 100%;
		margin: 0 auto;
  	@extend %block-padding-small;	
  	padding-left: 1rem;
    padding-right: 1rem;  	
		@include tablet {
			width: 100%;
    	padding-left: 0;
      padding-right: 0;  			
			margin-bottom: 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-content: flex-start;		
		}
		&.no_top_padding {
		  padding-top: 0;
		}
		&.no_bottom_padding {
		  padding-bottom: 0;
		}		
  	&.four_columns {
  	   .menu-box {
         	@include desktop {
         		width: calc(25% - 1rem);		
         		margin: .5rem;
         	}	
          &-headline {
            font-size: 1rem;
          }
  	   }
  	}		
	}	
	background: $graybg;
	text-align: left;
	padding: 1.5rem 2rem 2rem;
	border: 2px solid rgba($lightgray, .3);
	transition: $slow;
	margin-bottom: 1rem;
	&:hover {
		box-shadow: $shadow-hover;
	}
	@include tablet {
		width: calc(50% - 1rem);		
		margin: .5rem;
	}
	@include desktop {
		width: calc(33.33% - 2rem);		
		margin: 1rem;
	}	
	&-headline {
		background: $secondary;
		color: $white !important;
		padding: .5rem .75rem .5rem 2rem;
		text-transform: uppercase;
		display: table;
		position: relative;
		left: calc(-2rem - 2px);
		margin-bottom: 2rem;
		@extend %headline6;
		a {
		  color: $white;
		}
	}
	.menu-box-headline {
		@extend %headline6;
	}
	&-blurb {
		line-height: 1.1;
		@include tablet {
			font-size: 1.15rem;	
		}
		@include desktop {
			font-size: 1.25rem;				
		}	
		p {
			margin-bottom: 0;
		}
	}		
	&-list {
		list-style: none;
		margin: 1rem auto 0;
		padding: 0;
		@include tablet {
		}
		@include desktop {
			margin: 1.5rem auto 0;			
		}			
	}
	&-item {
		
	}
	&-link {
		color: $primary;
		transition: $bounce;
		padding: .5rem .2rem;
		display: block !important;
		position: relative;
		line-height: 1;
		left: -.2rem;
		&:hover {
			color: $secondary;
			left: 0rem;			
		}
		@include tablet {
			font-size: 1.05rem;	
			padding: .3rem .2rem;			
		}
		@include desktop {
			font-size: 1.15rem;				
		}		
    em {
      text-transform: uppercase;
      display: block;
      font-size: 70%;
      font-style: normal;
      color: $gray;
    }   		
	}
	
	&-floating-img {
		width: 100%;
		vertical-align: middle;
		&.no-link,
  	&-link {
  	  display: block;
  	  position: relative;
  	  z-index: 100;
  		float: right;
  		max-width: 30%;
  		margin: 0 0 1rem 1rem;
  		transition: $slow;
      &:hover {
    		box-shadow: $shadow-hover;
      }  		
  	}		
	}
	&-fullwidth-img {
		width: 100%;
		vertical-align: middle;
		&.no-link,
  	&-link {
  		float: none;
  		display: block !important;
  		margin: .5rem 0 1rem 0;
  		transition: $slow;
      &:hover {
    		box-shadow: $shadow-hover;
      }    		
  	}		
	}
	&-logos {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin-top: 1rem;
		justify-content: space-between;
		align-content: flex-start;
		align-items: stretch;	
  	&-link {
  		background: $white;
  		border: 1px solid $lightgray;
  		display: block;
  		margin: .25rem;
  		width: calc(50% - .5rem);		
  		transition: $bounce;
  		&:hover {
  		  box-shadow: $shadow;
  		}
  	}		
		&-img {
		  width: 100%;
		  vertical-align: middle;
		}
	}
}
