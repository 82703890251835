.child-list {
  margin: 0 auto;
  margin-bottom: 1rem;
  max-width: $desktop;
  padding: 0;
  @include desktop {
    margin-bottom: 1.5rem;
    padding: 0;
  }
	&.top_margin {
	  @extend %block-margin-top;
	}	
	&.bottom_margin {
	  @extend %block-margin-bottom;
	}	  
  &-item {
    padding: 1rem 0;
  	@extend %slide-up;
  	text-align: left;
    &.reveal {
    	@extend %slide-up-reveal;
    }
    &:hover {
      .child-list {
        &-title {
          color: $secondary;
        }        
      }
    }
  }
  &-link {
    display: block;
  }
  &-title {
    font-family: $headline;
    color: $primary;
    transition: $slow;
  	@extend %headline4;
  	margin-bottom: .25rem;
  }
  &-description {
    color: $black;
  	@extend %basic-text;
  	line-height: 1.1;
  }
}
