.issues {
  &-list {
		@extend %block-padding-small;
		width: 100%;
		margin: 0 auto;
		max-width: $desktop;
  }
	&-item {
		@extend %slide-up;
		&:hover {
		  .issues-item {
		    &-grid {
		      background: $lightestgray;
		    }
		    &-headline {
		      color: $secondary;
		    }
		    &-icon {
		      svg {
		        fill: $secondary;
		      }
		    }
		  }
		}
		&.reveal {
			@extend %slide-up-reveal;	
		}		
  	&-grid {
  	  display: block;
  		margin: 0 auto;
  		transition: $slow;
    	padding: 1rem;
  		@include tablet {
  			display: flex;
  			flex-wrap: nowrap;
  			justify-content: space-between;
  			align-content: flex-start;
  			align-items: stretch;			
  		}
  		@include desktop {
  			padding: 1.5rem;
  		}  		
  		@include lg {
  			padding: 2rem;
  		}  
  	}			
  	&-text {
  	  text-align: center;
  		@include tablet {
  		  flex: 1;
    	  text-align: left;
    	  padding-left: 1rem;
  		}
  		@include desktop {
    	  padding-left: 1.5rem;
  		}  		
  		@include lg {
    	  padding-left: 2rem;
  		}  		
  	}
  	&-icon {
  	  margin: 0 auto 1rem;
  	  width: 6rem;
  		@include tablet {
  		  margin-bottom: 0;
    	  width: 5rem;
  		}  	 
  		@include desktop {
    	  width: 9rem;
  		}   		
  	  svg {
  	    width: 100%;
  	    fill: $primary;
  		  transition: $slow;
  	  }
  	}
		&-headline {
			font-family: $headline;
			color: $primary;
			font-size: 1.3rem;
			margin: 0 auto;
			margin-bottom: .25rem;
  		transition: $slow;
			@include tablet {
				font-size: 1.6rem;
  			margin-bottom: .35rem;
			}		
			@include desktop {
				font-size: 1.9rem;	
			  margin-bottom: .5rem;
			}	
		}
		&-teaser {
		  color: $black;
			@extend %basic-text;	
		}
	}  
}
.other-issues {
	background: linear-gradient(-180deg, darken($primary, 13%) 60%, darken($primary, 20%) 100%);
	@extend %block-padding-small;
	&-headline {
		color: $white;
		margin: 0 auto .75rem auto;
		@extend %slide-up;	
  	@extend %headline3;
  	color: $white;
		&.reveal {
			@extend %slide-up-reveal;	
		}					
		&-link {
		  color: $white;
		  transition: $slow;
		  padding: .5rem;
		  display: inline-block;
		  &:hover {
		    background: $secondary;
		    color: $white;
		  }
		  &:focus {
      	box-shadow: 0 0 0 4px $green;
		  }
		}
	}			
  &-wrap {
    max-width: $max-width;
    margin: 0 auto;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: flex-start;
		align-content: flex-start;
  }
  &-item {
    position: relative;
		@extend %slide-up;	
		padding: 1rem;
		width: 50%;
		@include tablet {
  		width: 20%;
  		padding: .5rem;
		}
		@include desktop {
  		padding: 1.5em;
		}		
		@include md {
  		padding: 2em;
		}			
		@include lg {
  		padding: 2.5rem;
		}			
		&.reveal {
			@extend %slide-up-reveal;	
		}			
		&-icon {
		  margin-bottom: 1rem;
		  svg {
		    fill: $primary;
		    width: 60%;
		    transition: $slow;
		  }
		}
		&-title {
  		font-family: $headline;			
  		color: $primary;
    	@extend %headline6;
    	color: $white;
		}
    &-link {
      color: $bounce;
      transition: $slow;
      display: block;
      text-align: center;
      padding: 1rem;
      &:hover {
        background: $primary;
        .other-issues-item {
      		&-icon {
      		  svg {
      		    fill: $white;
      		  }
      		}
        }
      }
      &:focus {
        background: darken($primary, 10%);
      	box-shadow: 0 0 0 4px $white;
        .other-issues-item {
      		&-icon {
      		  svg {
      		    fill: $white;
      		  }
      		}
        }
      }
      
    }
  }
}
