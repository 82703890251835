.jobs {
	&-grid {
		max-width: $max-width;
		width: 100%;
		margin: 0 auto;
		padding-top: 1rem;
		@extend %block-padding;
		@include phone {
		  padding-left: 1rem;
		  padding-right: 1rem;
		}
		@include tablet {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-content: flex-start;
			align-items: stretch;			
		}
		&-item {
			position: relative;
			transition: $bounce;
			@extend %slide-up;	
			padding: .5rem;
			@include tablet {
  			padding: 1rem;
				width: 33.33%;
				&:nth-child(3n+1) {
					transition-delay: .2s;
				}
				&:nth-child(3n+2) {
					transition-delay: .4s;
				}	
				&:nth-child(3n+3) {
					transition-delay: .6s;
				}			
			}
			&.reveal {
				@extend %slide-up-reveal;	
			}			
		}
		&-aspect {
			background: $secondary;
			@include aspect(4,3);
			position: relative;
			overflow: hidden;
			display: block;
			transition: $bounce;
			&:hover {
				box-shadow: $shadow;
				.jobs-grid-photo {
					opacity: .1;
					transform: scale(1.1);
				}				
			}			
		}
		&-headline {
			font-family: $headline;
			width: 100%;
			@include absolute(left 0 top 50%);	
			transform: translateY(-50%);
			z-index: 200;
			color: $white;
			padding: 1rem;
			font-size: 1.3rem;
			margin: 0 auto;
			@include tablet {
				font-size: 1.4rem;				
				padding: 1.5rem;
			}		
			@include desktop {
				font-size: 1.6rem;				
				padding: 2rem;
			}		
		}		
		&-photo {
			@include size(100%);
			@include absolute(top 0 left 0);				
			background-repeat: no-repeat;  
			background-position: center center;
			background-size: cover;  		
			z-index: 100;
			transition: $veryslow; 
			opacity: .4;
		}		
	}	
}
.other-members {
  background: $lightestgray;
	@extend %block-padding;
	&-headline {
		font-family: $headline;			
		color: $secondary;
		font-size: 1.5rem;
		margin: 1rem auto .75rem auto;
		@extend %slide-up;	
		@include tablet {
		  margin-top: 0;
			font-size: 1.5rem;
		}		
		@include desktop {
			font-size: 2rem;
			margin: 0 auto 1rem auto;				
		}	
		@include desktop {
			font-size: 2.25rem;
		}
		&.reveal {
			@extend %slide-up-reveal;	
		}					
		&-link {
		  color: $gray;
		  transition: $slow;
		  &:hover {
		    color: $secondary;
		  }
		}
	}			
  &-wrap {
    max-width: $desktop-medium;
    margin: 0 auto;
		@include tablet {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: flex-start;
			align-content: flex-start;
		}    
  }
  &-item {
    position: relative;
			@extend %slide-up;	
		@include desktop {
		  width: 25%;
		}
		&.reveal {
			@extend %slide-up-reveal;	
		}				
		&-title {
			font-family: $headline;
			font-size: 1.1rem;
		}
    &-link {
      color: $primary;
      transition: $slow;
      display: block;
      text-align: left;
      padding: 1rem;
      &:hover {
        color: $secondary;
      }
      &:after {
  			@include absolute(left 0 top 50%);				
  			@include size(.5rem);				
  			border-radius: 50%;
  			transform: translateY(-50%);
      	content: "";
      	display: block;
      	background: $primary;
      }      
    }
  }
}
