.advantage {
	&-banners {
		background: $graybg;
		@include tablet {
			padding: .5rem 0;
		}
		@include desktop {
			padding: 1rem 0;		
		}
		&.blue {
		  background: $blue;
		}
		&.dark-blue {
		  background: darken($darkblue, 10%);
		}
		&.green {
		  background: $green;
		}
		&.dark-green {
		  background: darken($green, 10%);
		}
		&-grid {
			width: 100%;
			max-width: $max-width;		
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: flex-start;
			align-items: stretch;			
			margin: 0 auto;
			padding: .5rem;
			@include tablet {
				padding: 0;
			}
			@include desktop {
			}
		}	
		&-item {
			width: calc(50% - .5rem);		
			margin: .25rem;		
			border: 1px solid rgba($lightgray, .3);
			transition: $bounce;
			flex: 1;
			&:hover {
				box-shadow: $shadow-hover;
				transform: scale(1.03);
			}
			@include tablet {
				width: calc(25% - 1rem);		
				margin: .5rem;
			}
			@include desktop {
				width: calc(25% - 2rem);		
				margin: 1rem;
			}	
		}
		&-img {
			width: 100%;
			vertical-align: middle;
		}
	}
	&-testimonials {
		background: $graybg;		
		width: 100%;
		margin: 0 auto;
  	@extend %block-padding-small;	
  	padding-left: 0;
    padding-right: 0;   	
		&-grid {
			margin: 0 auto;
			@include tablet {
				max-width: $max-width;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-content: flex-start;			
			}
		}
		&-item {
			background: $secondary;
			margin: 1rem;
			position: relative;
			@extend %slide-up;	
			&.reveal {
				@extend %slide-up-reveal;	
			}				
			@include tablet {
				padding-bottom: 8rem;				
				width: calc(33.33% - 2rem);
				&:nth-child(1) {
					transition-delay: .2s;
				}
				&:nth-child(2) {
					transition-delay: .4s;
				}	
				&:nth-child(3) {
					transition-delay: .6s;
				}			
			}	
			@include desktop {
				padding-bottom: 8rem;		
			}			
		}
		&-text {
			padding: 1.5rem;
			color: $white;
			margin: 0;
			@include tablet {
				padding: 1.75rem;		
			}	
			@include desktop {
				padding: 2rem;
			}					
		}
		&-quote {
			font-size: 1.3rem;
			display: block;
			margin-bottom: 1.5rem;
			@include tablet {
				font-size: 1.4rem;
			}
		}
		&-cite {
			width: 100%;
			display: block;
			font-style: normal;
			padding-bottom: 1em;
			@include tablet {
				@include absolute(left 0% bottom 0%);
				padding-bottom: 1.75rem;		
			}	
			@include desktop {
				padding-bottom: 2rem;
			}			
		}	
		&-headshot {
			width: 18%;
			margin-bottom: 1rem;
			border-radius: 50%;
		}			
		&-name {
			display: block;
		}			
	}
  &-newsletter {
  	background: $secondary;
  	color: $white;
  	border: none;
  	padding-top: 2rem;	
  	&-headline {
  		font-family: $headline;
  		font-size: 1.4rem;	
  		margin-bottom: 1rem;
  		@include tablet {
  			font-size: 1.6rem;
  		}
  		@include desktop {
  			font-size: 1.8rem;
  			margin-bottom: 1.5rem;
  		}			
  	}
  	&-blurb {
  		font-size: 1rem;
  		margin-bottom: 1rem;					
  		@include tablet {
  			font-size: 1.15rem;	
  		}
  		@include desktop {
  			font-size: 1.25rem;	
  			margin-bottom: 1.5rem;			
  		}			
  	}	
  	&-form {
  	  transition: $slow;
    	&-thanks {
      	@extend %block-padding-small;
    	  width: 100%;
    	  opacity: 0;
    	  visibility: hidden;
    		@include absolute(top 50% right 0);	
    		transform: translateY(-50%);
    		transition: $slow;
    		transition-delay: .4s;
    		z-index: 1;
    		text-align: center;
    	}  	  
  	}
  	&-grid {
  		width: 100%;
  		display: flex;
  		flex-wrap: wrap;
  		justify-content: space-between;
  		align-content: flex-start;					
  	}
  	&-field {
  		width: 50%;
  		position: relative;
  		&:first-child {
  			width: 100%;
  			padding-bottom: .35rem;
  		}
  		&:nth-child(2) {
  			padding-right: .35rem;
  		}
    	div.error {
    	  background: $red;
    	  padding: .15rem;
    	  font-size: 70%;
    	  color: $white;
    	  display: inline-block;
    	  border: 1px solid $white;
    		@include absolute(top 0 left 0);	
    		transform: translateY(-50%);
    	}		
  	}
  	&-label {
  		@include screen-reader-text;
  	}
  	&-input {
  		background: transparent;
  		padding: 1rem;
  		font-size: 1.3rem;
  		border: 0;
  		width: 100%;
  		color: $white;		
  		border: 1px solid $white;
  		transition: $slow;
  		@include placeholder {
  			color: $white;
  			transition: $slow;
  		}
  		&:hover {
  			@include placeholder {
  				color: $white;
  			}
  		}
  		&:focus {
  			@include placeholder {
  				color: $white;
  			}
  		} 
  	}	
  	&-button {
  	 &-container {
  	   width: 100%;
  	   padding-top: .75rem;
  	   text-align: center;
  	   button {
  	     padding-top: .75rem;
  	     padding-bottom: .75rem;
  	   }
  	 }
  		background: $white;
  		color: $secondary;		
  		width: 50%;
  		border: 0;
  		cursor: pointer;
  		font-family: $headline;
  		text-transform: uppercase;
  		transition: $slow;
  		&:hover {
  			 background: $green;
  			 color: $white;
  		}
  		&:focus {
  				
  		}			
  	}		
  	&.submitted {
  	  .advantage-newsletter-form {
  	    opacity: 0;
  	    visibility: hidden;
  	    z-index: 1; 
  	    &-thanks {
    	    opacity: 1;
    	    visibility: visible;
    	    z-index: 100;
  	    }
  	  }
  	}	
  }	
}
.page-afscme-advantage {
	.related-news {
	  background: $white;
	}  
  @include desktop {
	  .photo-promo-item {
	    flex: none;
	    &:first-of-type {
	      width: 66.66%;
	    }
	    &:last-of-type {
	      flex: 1;
	    }	    
	  }
	}
}
