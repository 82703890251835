.leg-report {
  &-details {
    text-transform: uppercase;
  }
  &-excerpt-headline {
    @extend %headline4;
    line-height: 1.2;
    color: $gray;
    margin-bottom: 1rem;
  }
  &-join {
    background: $secondary;
    @extend %block-padding-small;	
    position: relative;
    &-headline {
      @extend %headline6;
      color: $white;
      @extend %slide-up;	
      &.reveal {
        @extend %slide-up-reveal;	
      }
    }
    &-container {
      transition: $slow;
    }
    &-form {
  		width: 100%;
  		max-width: 45rem;
  		display: flex;
  		flex-wrap: wrap;
  		flex-direction: row;
  		justify-content: center;
  		align-items: stretch;
  		align-content: stretch;	
  		margin: 1rem auto 0; 
  		@include tablet {
    		flex-wrap: nowrap;
  		}
      @extend %slide-up;	
      &.reveal {
        @extend %slide-up-reveal;	
      }
      .join-field {
        @include phone {
          &:first-of-type {
            flex: 0 0 60%;
          }
          &:last-of-type {
            flex: 0 0 40%;
          }
        }
      }
      .join-button {
        @include phone {
          margin-top: .5rem;
          padding: .75rem;
        }
      }
    	&-thanks {
    		@include absolute(top 50% left 50%);	
    		transform: translateX(-50%) translateY(-50%);    	  
    	  padding: 1rem;
    	  width: 100%;
    	  opacity: 0;
    	  visibility: hidden;
    		transition: $slow;
    		transition-delay: .4s;
    		z-index: 1;
    		text-align: center;
      	color: $white;
      	@extend %basic-text;
        h2, h3 {
        	@extend %headline2;
        	color: $white;
        	margin-bottom: 1rem;
        }
    	}	
    }
  	&.submitted {
  	  .leg-report-join-container {
  	    opacity: 0;
  	    visibility: hidden;
  	    z-index: 1; 
  	  }
	    .leg-report-join-form-thanks {
  	    opacity: 1;
  	    visibility: visible;
  	    z-index: 100;
	    }  	  
  	}     
  } 
  &-photo {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.icymi {
  &-headline {
    background: $blue;
    @extend %headline3;
    padding: 1.75rem;
    color: $white;
    @include desktop {
      padding: 1.75rem;
    }
  }
}
