.current-section-nav {
	display: none;
	@include desktop {
  	display: block;
	}
	padding: .35rem;
	background: $secondary;
	box-shadow: .1rem .2rem 1rem 0 rgba($black,.35);
	&-ul {
		list-style: none;
		margin: 0 auto;
	}
	&-li {
		display: inline-block;
		&.active {
			.current-section-nav-link {
				background: $lightblue;
			}	
		}
	}	
	&-link {
		color: $white;
		font-size: 1.1rem;
		transition: $slow;
		padding: .5rem .75rem;
		display: block;
		@include desktop {
  		margin: 0 .25rem;
		}		
		@include xl {
  		margin: 0 .3rem;
		}
		&:hover {
			background: $primary;
		}
		&:focus {
			background: darken($primary, 10%);
		}
	}		
}
