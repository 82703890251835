.sidebar-banner {
	width: 100%;
	text-align: center;
	position: relative;
	margin-bottom: 1rem;
	transition: $bounce;
	background: $secondary;
	box-shadow: $shadow;
	@extend %slide-up;
	&.reveal {
  	@extend %slide-up-reveal;
	}
	&:hover {
  	background: darken($primary, 10%);
		box-shadow: $shadow-hover;
	}
	@include tablet {
	  margin-bottom: 1.25rem;
	}
	@include desktop {
	  margin-bottom: 1.75rem;
	}	
	@include lg {
	  margin-bottom: 2rem;
	}		
	&:last-of-type {
	  margin-bottom: 0 !important;
	}
	&.green {
  	background: $green;
  	&:hover {
    	background: darken($green, 15%);
  	}
	}	
	&.gray {
  	background: $gray;
  	&:hover {
    	background: darken($gray, 15%);
  	}
	}		
	&.dark-gray {
  	background: $darkgray;
  	&:hover {
    	background: darken($darkgray, 15%);
  	}
	}	
	&.white {
  	background: $white;
  	&:hover {
    	background: darken($white, 15%);
  	}
  	.sidebar-banner-headline {
  	  color: $green;
  	}
	}		
	&:after {
		@include size(100%,30%);
		display: block;
		content: "";
		z-index: 10;
		transition: $bounce;
		opacity: .6;
	}
	&:after {
		@include absolute(bottom 0 left 0);
		background: linear-gradient(0deg, rgba($black,.5) 0%, rgba($black,0) 100%);
	}	
	&-aspect {
		@include aspect(1,1);
		overflow: hidden;
		position: relative;
		@include tablet {
			@include aspect(1,1);
		}
	}
	&-link {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 80% auto;
		display: block;
		@include size(100%);
		@include absolute(left 0 top 0);
		z-index: 500;
		transition: $bounce;
  	&:focus {
    	box-shadow: inset 0 0 0 8px $green;
  	}			
	}			
	&-logo {
    max-height: 7rem;
    margin-bottom: .5rem;
	}	
	&-headline {
		font-family: $headline;
		line-height: 1;
		color: $white;
		margin: 0 auto 1rem;
    @extend %headline4;
    @include desktop {
		  max-width: 80%;
    }
		&.smaller {
      @extend %headline5;
		}
	}
	&-text {
		width: 100%;
		@include absolute(top 50% left 0%);	
		transform: translateY(-50%);
		padding: 2rem;
    text-align: center;
    @extend %basic-text;
		transition: $bounce;
	}
	&-teaser {
	  color: $white;
    @extend %basic-text;
    text-align: center;
    line-height: 1.1;
    p:last-of-type {
      margin-bottom: 0;
    }
	}	
	.button-block {
	  margin-top: 1rem;
    transition: none;
    opacity: 1;
    transform: translateY(0);
	}
	&-photo {
		@include size(100%, 100%);
		@include absolute(top 0 left 0);	
		z-index: 1;
		background-repeat: no-repeat;  
		background-position: center center;
		background-size: cover;  		
		transition: $bounce; 
		opacity: .5;
		transform: scale(1);	
		&.no_dim {
		  opacity: 1;
		}
	}
	&:hover {
		.button {
	  	transform: scale(1) !important;
		}	  
		.sidebar-banner {
			&-text {
				transform: translateY(-50%) scale(1.07);
			}		
			&-photo {
				opacity: .65 !important;
				transform: scale(1.1);
			}	
		}
	}
	&.letterbox {
		.sidebar-banner {
			&-aspect {
  			@include aspect(16,9);
			}
		}			
	}
	&.flex-with-content {
		.sidebar-banner {
			&-aspect {
				padding-top: 0 !important;
			}
			&-link {
				@include relative(top auto left auto);	
				@include phone {
				  padding-top: 3rem;
				  padding-bottom: 3rem;
				}					
			}
			&-text {
				@include relative(top auto left auto);
				transform: none;
			}
		}			
	}	
}	
