.sidebar {
  &-header {
		background: $secondary;
		color: $white;
		font-family: $headline;
		padding: .75rem .75rem;
		text-transform: uppercase;
		margin-bottom: .5rem;
		text-align: left;
		@extend %slide-up;	
		@extend %headline7;	
		&.reveal {
			@extend %slide-up-reveal;	
		}
		&-link {
		  display: block;
		  color: $white;
		  transition: $slow;
  		&:focus {
      	box-shadow: 0 0 0 2px $green;
  		}		  
		}
		&:first-of-type {
		  @include phone {
		    margin-top: 2rem;
		  }
		}
		&.green {
		  background: $green;
		}
		&.gray {
		  background: $gray;
		}		
		&.darkgreen {
		  background: darken($primary, 15%);
		}
		&.darkblue {
		  background: darken($secondary, 15%);
		}	
		&.darkgray {
		  background: $darkgray;
		}	
		&.with-link {
		  transition: $slow;
		  &:hover {
		    background: darken($secondary, 15%);
		  }
		}
  }  
  &-list {
    &-item {
  		@extend %slide-up;	
  		&.reveal {
  			@extend %slide-up-reveal;	
  		}      
      &-link {
    		@extend %headline6;	
        color: $secondary;
        transition: $slow;
        display: block;
        padding: .5rem;
        text-align: left;
        display: block;
        &:hover {
          color: $primary;
        }
    		&:focus {
        	box-shadow: 0 0 0 2px $green;
    		}        
      }
      &-date {
        @extend %basic-text;	
        font-family: $body;
        color: $gray;
        text-transform: uppercase;
        font-size: 80%;
      }
    }
  }
}    
