.brochure-download {
	background: linear-gradient(-180deg, darken($primary, 13%) 60%, darken($primary, 17%) 100%);
	color: $white;
	opacity: 0;
	transition: $slow;
	&-wrap {
		width: 100%;
		max-width: $max-width-text;	
		text-align: center;
		padding: 1rem;		
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		align-content: stretch;
		align-items: flex-end;			
		@include tablet {
			padding: 2rem 0;
		}
		@include desktop {
			padding: 3rem 1rem 0;			
		}		
		&:hover {
		  .brochure-download {
	    	&-icon {
	    	  background: $white;
	    	  transition-delay: 0 !important;
	    	}
  			&-cover-img {
  				transform: rotate(-3deg) !important;
  			}		    	
		  }
		}
		&:focus {
  	  box-shadow: inset 0 0 0 4px $green;
		  .brochure-download {
	    	&-icon {
	    	  background: $white;
	    	  transition-delay: 0 !important;
	    	}
  			&-cover-img {
  				transform: rotate(-3deg) !important;
  			}		    	
		  }
		}		
	}
	&-cover {
		width: 35%;
		position: relative;
		align-self: flex-end;
		@extend %slide-up;	
		display: none;
		transition-delay: .2s !important;
		@include tablet {
		  display: block;
		}
		&-container {
			overflow: hidden;
			width: 100%;
			height: 17rem;
			@include absolute(left 0 bottom 0);
		}		
		&-img {
			vertical-align: middle;
			width: 80%;
			vertical-align: bottom;
			transform: rotate(-0);
			transform-origin: left top;
			transition: $bounce;
			@include relative(left 0 top 2rem);
			top: 2rem;
		}
	}
	&-headline {
		margin: 0;
		text-align: left;
		color: $white;
		@extend %slide-up;	
		@extend %headline2;	
		transition-delay: .4s !important;		
		@include tablet {
			font-size: 2rem;
			padding-left: 2rem;
			padding-bottom: 3rem;
		}
		@include desktop {
			font-size: 2.2rem;
			padding-bottom: 4rem;
		}			
	}
	&-icon {
	  @include size(7.5rem, 5rem)
		padding: 1rem 1rem;
		background: $primary;
		display: block;
    align-self: center;
		@extend %slide-up;	
		@include tablet {
      height: 7rem;
      margin-bottom: 2rem;
      padding: 1.5rem 1rem;
		}
		@include desktop {
			margin-bottom: 3rem;			
		}			
		&-svg {
			width: 100%;
			fill: darken($primary, 20%);
		}
	}
	&.reveal {
		opacity: 1;
		.brochure-download {
			&-headline,
			&-icon,
			&-cover { 
				@extend %slide-up-reveal;		
			}
			&-cover-img {
				transform: rotate(-5deg);
			}
		}
	}
}
