.accordion {
	padding: 1rem 0;
	border-top: 1px solid rgba($black,.1);
	max-width: $desktop;
	margin: 0 auto;
	display: block;
	&:first-child {
		margin-top: 1rem;
		@include desktop {
			margin-top: 2rem;
		}
	}
	&:last-of-type {
		border-bottom: 1px solid $lightgray;
	}
	&-header {
		background: transparent;
		width: 100%;
		outline: 0;
		border: 0;
		cursor: pointer;
		display: flex;
		align-items: flex-start;
		&-icon {
			@include size(1.75rem);
			position: relative;
			margin-right: .75rem;
			border: 1px solid $green;
			border-radius: 50%;
			padding: .5rem;
			transition: $slow;
			flex: 0 0 auto;
			&:before {
				@include size(2px, 1rem);
				content: "";
				display: block;
				background: $green;
				@include absolute(left 50% top 50%);
				transform: translateX(-50%) translateY(-50%);
				transition: $slow;
			}
			&:after {
				@include size(1rem, 2px);
				content: "";
				display: block;
				background: $green;
				@include absolute(left 50% top 50%);
				transform: translateX(-50%) translateY(-50%);
				transition: $slow;
			}
		}
		&-title {
			color: $blue;
			@extend %headline6;
			font-weight: 700;
			text-align: left;
			line-height: 1.4;
			flex: 0 1 auto;
			transition: $slow;
		}
		&:hover {
			.accordion-header {
				&-icon {
					background: $green;
					border-color: $green;
					&:before {
						background: $white;
					}
					&:after {
						background: $white;
					}
				}
				&-title {
					color: $green;
				}
			}
		}
	}
	&-content {
		max-height: 0px;
		overflow: hidden;
		transition: max-height 1s cubic-bezier(0.57,0.2,0.21,0.89);
		text-align: left;
		&-body {
			padding: .5rem 0 .5rem 2.25rem;
			@extend %basic-text;
		}
	}
	&.accordion-open-trigger {
		.accordion {
			&-header {
				&-icon {
					background: $blue;
					border-color: $blue;
					&:before {
						background: $white;
						transform: translateX(-50%) translateY(-50%) rotate(90deg);
					}
					&:after {
						background: $white;
					}
				}
				&-title {
					color: $green;
				}
			}
			&-content {
				max-height: 3000px;
				display: block;
			}
		}
	}
	  &-menu-box {
	     margin: 0 auto !important;
	     border-top: none;
	     .accordion {
	        &-header {
         		color: $primary;
         		transition: $bounce;
         		padding: .5rem .2rem;
         		display: block !important;
         		position: relative;
         		line-height: 1;	         
	        }
	     }
	  }
}
