@keyframes pulse {
 	0% {
  		transform: scale(1);
  		box-shadow: 0 0 0 0 rgba(0,0,0,0);
 	}
 	50% {
  		transform: scale(1.2);
  		background-color: darken($darkgreen, 6%);
  		box-shadow: $button-shadow;
 	}
 	100% {
  		transform: scale(1);
  		box-shadow: 0 0 0 0 rgba(0,0,0,0);
 	}
}


.join {
	&-row {
		width: 100%;
  margin-bottom: 1rem;
		@include tablet {
   			display: flex;
   			flex-wrap: nowrap;
   			flex-direction: row;
   			align-items: stretch;
   			align-content: stretch;
      margin-bottom: 1.5rem;
		}
	}	
	&-sidebar {
			background: $darkgreen;
			position: relative;	 
	  padding: 2rem;
	  margin-bottom: 1rem;
	  @include desktop {
	    margin-bottom: 2rem;
	  }
  	&-headline {
  		color: $white;
  		margin-bottom: 0;
  		@extend %headline4;
  		text-transform: uppercase;
  	}		
  	&-blurb {
  		font-family: $body;			
  		color: $white;
  		display: block;
  		font-size: .9rem;
  		line-height: 1.1;
  		margin: .25rem auto 1.5rem;
  		@include desktop {
  			font-size: 1rem;
  		}					
  	}	  
  	&-form {
  		width: 100%;
  		max-width: 51rem;
  		margin: .75rem auto .75rem;
  		@extend %slide-up;
  		transition-delay: .6s !important;		
  		@include desktop {
     			margin: 2rem auto 1rem;
  		}		
  		&.reveal {
      		@extend %slide-up-reveal;
  		}  		
  	}  	
	}
	&-layer {
			background: $darkgreen;
			position: relative;
 		@extend %block-padding;
 		&.gray {
 		   background: $lightestgray !important;
 		}
 		&.white {
 		   background: $white !important;
 		} 		
   &.gray, &.white {
       .join {
           &-layer {
              &-headline {
                  color: $darkgreen;
              }
              &-blurb {
                 color: $black;
                 max-width: 40em;
                 p {
                    margin-bottom: 1rem;
                    &:last-of-type {
                       margin-bottom: 2rem;
                    }
                 }
              }
              &-disclaimer {
                  color: $gray;
                  a {
                    color: $green;
                    &:hover {
                      color: $blue;
                    }
                  }
              }
              &-button {
                  background-color: $darkgreen;
                  color: $white;
                  &:hover {
                     background-color: $darkblue;
                  }
              }
           }
           &-input {
              background: $green;
              border: 1px solid $darkgreen;
              @include placeholder {
                 opacity: 1;
              }
              &:hover {
                 background: $blue;
                 border: 1px solid $darkblue;
              }
              &:focus {
                 background: $white;
                 border: 1px solid $darkgreen;
                 @include placeholder {
                    color: $darkgreen;
                 }
              }
              &-textarea {
                 height: 10rem;
              }
           }
       }
   }
   &.submitted {
    .join-layer-content  { 
     opacity: 0;
     visibility: hidden;
    }
    .join-content-thanks {
     opacity: 1;
     visibility: visible;
    }
   } 		

   &-expander {
     padding: 1rem;
     @include tablet {
       padding: 1rem;
     }
     .accordion {
        border-top: none;
        margin-top: 0 !important;
        &.reveal {
            .join-layer-headline,
            .join-layer-blurb {
               transform: scale(1);
               opacity: 1;
            }
        }
        .join-layer {
          &-headline, 
          &-blurb {
             transform: scale(.7);
             opacity: 0;
             transition: $bounce;
          }
          &-headline {
             display: inline-flex;
             flex-direction: row;
             flex-wrap: nowrap;
             justify-content: center;
             align-content: center;
             align-items: center;
          }
          &-blurb {
            transition-delay: .3s;
          }
        }
        &-header {
          text-align: center;
          display: block;
          &:hover {
          			.accordion-header {
            				&-icon {
             					background: $white;
             					border-color: $white;
 					         			transform: scale(1.1);
             					&:before {
             						background: $green;
             					}
             					&:after {
             						background: $green;
             					}
            				}   
          			}
          }
          &-icon {
            margin-left: .5rem;
         			@include size(1.75rem);
         			margin-right: 0;
         			border: 2px solid $white;
 			       	animation: pulse 2s infinite;
         			@include desktop {
              margin-left: 1rem;
           			@include size(2.5rem);
         			}
         			&:before {
          				@include size(2px, 1rem);
          				background: $white;
          				@include tablet {
            				@include size(4px, 1rem);
          				}
         			}
         			&:after {
 			          @include size(1rem, 2px);
          				background: $white;
          				@include tablet {
   			          @include size(1rem, 4px);
          				}          				
         			}
          }
        }
        &-content {
           transition: 1s max-height $slow-curve;
           transition-delay: 0s;
           text-align: center;
           margin: 0 auto;
        }
      		&.accordion-open {
      			.accordion {
      				&-header {
      					&-icon {
      						background: $white;
      						border-color: $green;
            animation: none;
      						&:before {
      							background-color: $green;
      						}
      						&:after {
      							background: $green;
      						}
      					}
      				}
      				&-content {
      					padding-top: 1rem;
      					padding-bottom: 1rem;
      					@include desktop {
      						padding-top: 2rem;
      						padding-bottom: 1rem;
      					}   
      				}
      			}
      		}  
     }
   }
			&-content {
  		@extend %slide-up;
  		max-width: $desktop;
  		margin: 0 auto;
    &.reveal {
   		@extend %slide-up-reveal;
    }
			}
  	&-headline {
  		color: $white;
  		margin-bottom: 0;
  		@extend %headline2;
  		text-transform: uppercase;
  	}		
  	&-blurb {
  		font-family: $body;			
  		color: $white;
  		display: block;
  		font-size: 1.1rem;
  		line-height: 1.1;
  		margin: .25rem auto;
  		@include tablet {
  			font-size: 1rem;
  		}		
  		@include desktop {
  			font-size: 1.3rem;
  		}					
  	}	 	
  	&-form {
  		width: 100%;
  		max-width: 51rem;
  		margin: .75rem auto;
  		@extend %slide-up;
  		transition-delay: .6s !important;		
  		@include tablet {
  			margin: 1rem auto;
  		}	
  		@include desktop {
  			margin: 1.5rem auto;
  		}		
  		&.reveal {
   		@extend %slide-up-reveal;
  		}  		
  	}
  	&-field.email {
  		flex: 0 0 45%;
  		@include tablet {
  			flex: 0 0 45%;
  		}	
  		@include desktop {
  			flex: 0 0 42%;
  		}			
  		@include md {
  			flex: 0 0 50%;
  		}			
  	}	
  	&-button {
  		background: $white;
  		color: $darkgreen;
  		cursor: pointer;
  		border: 0;
  		font-family: $headline;
  		padding: 1.25rem 1.75rem;
  		font-size: 1rem;
  		text-transform: uppercase;
  		@extend %headline3;
  		white-space: nowrap;
  		transition: $slow;
  		&:hover {
  			background: darken($darkgreen, 10%);	
  			color: $white;
  		}
  		&:focus {
      	box-shadow: 0 0 0 4px $green;
  		}
  		@include tablet {
  			font-size: .75rem;			
  		}				
  		@include desktop {
  			font-size: 1rem;	
  		}		
  	}	
  	&-disclaimer {
  		font-size: .7rem;
  		line-height: 1;
  		display: block;
  		color: $white;
  		margin-top: 1.5rem;
  		@include tablet {
  			font-size: .7rem;			
  		}
  		@include desktop {
  			font-size: .8rem;	
  			line-height: 1.15rem;
   		margin-top: 2rem;
  		}			
  		a {
  			color: $white;
  		}
  	}	  	
	}
}
