.news-list {
  text-align: left;
  margin-top: 1rem;
  @include tablet {
    margin-top: 1.25rem;
  }
  @include desktop {
    margin-top: 1.75rem;
  }
  @include lg {
    margin-top: 2rem;
  }  
  &:first-of-type {
    margin-top: 0;
  }
  &-header {
		background: $secondary;
		color: $white;
		font-family: $headline;
		padding: .75rem .75rem;
		text-transform: uppercase;
		margin-bottom: .5rem;
		@extend %slide-up;	
		@include desktop {
		  font-size: 1.1rem;
		}
		&.reveal {
			@extend %slide-up-reveal;	
		}
	}
	&-item {
		@extend %slide-up;	
		&.reveal {
			@extend %slide-up-reveal;	
		}
	}
	&-title {
		font-family: $body;
	  font-weight: 300;
		@extend %headline6;	
		transition: $slow;
	}
	&-date {
	  text-transform: uppercase;
	  font-size: 80%;
	}
	&-link {
    color: $gray;
    transition: $slow;
		@extend %slide-up-reveal;	
		font-weight: 300;
		display: block;
		padding: .4rem;
    @include tablet {
		  padding: .6rem;
    }
    @include lg {
		  padding: .75rem;
    } 			
    &:hover {
      .news-list {
        &-title {
          color: $primary;
        }
      }
    }
		&:focus {
    	box-shadow: 0 0 0 2px $green;
      .news-list {
        &-title {
          color: $primary;
        }
      }    	
		}     
	}
}
